namespace $ {
	export class $hyoo_idea_plural extends $mol_object2 {
		
		/**
		 * ```tree
		 * zero @ \
		 * ```
		 */
		zero() {
			return this.$.$mol_locale.text( '$hyoo_idea_plural_zero' )
		}
		
		/**
		 * ```tree
		 * one @ \
		 * ```
		 */
		one() {
			return this.$.$mol_locale.text( '$hyoo_idea_plural_one' )
		}
		
		/**
		 * ```tree
		 * two @ \
		 * ```
		 */
		two() {
			return this.$.$mol_locale.text( '$hyoo_idea_plural_two' )
		}
		
		/**
		 * ```tree
		 * few @ \
		 * ```
		 */
		few() {
			return this.$.$mol_locale.text( '$hyoo_idea_plural_few' )
		}
		
		/**
		 * ```tree
		 * many @ \
		 * ```
		 */
		many() {
			return this.$.$mol_locale.text( '$hyoo_idea_plural_many' )
		}
		
		/**
		 * ```tree
		 * other @ \
		 * ```
		 */
		other() {
			return this.$.$mol_locale.text( '$hyoo_idea_plural_other' )
		}
	}
	
}

