namespace $ {
	export class $hyoo_idea_feed_page extends $mol_page {
		
		/**
		 * ```tree
		 * person $hyoo_idea_person
		 * ```
		 */
		@ $mol_mem
		person() {
			const obj = new this.$.$hyoo_idea_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * title @ \Feed
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_idea_feed_page_title' )
		}
		
		/**
		 * ```tree
		 * tools / <= Close
		 * ```
		 */
		tools() {
			return [
				this.Close()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body / <= Posts
		 * ```
		 */
		body() {
			return [
				this.Posts()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Close null
		 * ```
		 */
		Close() {
			return null as any
		}
		
		/**
		 * ```tree
		 * posts_after* /
		 * ```
		 */
		posts_after(id: any) {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * post* $hyoo_idea_post
		 * ```
		 */
		@ $mol_mem_key
		post(id: any) {
			const obj = new this.$.$hyoo_idea_post()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Post* $hyoo_idea_post_card post <= post*
		 * ```
		 */
		@ $mol_mem_key
		Post(id: any) {
			const obj = new this.$.$hyoo_idea_post_card()
			
			obj.post = () => this.post(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Posts $mol_infinite
		 * 	after* <= posts_after*
		 * 	Row* <= Post*
		 * ```
		 */
		@ $mol_mem
		Posts() {
			const obj = new this.$.$mol_infinite()
			
			obj.after = (id: any) => this.posts_after(id)
			obj.Row = (id: any) => this.Post(id)
			
			return obj
		}
	}
	
}

