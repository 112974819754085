namespace $ {
	export class $hyoo_idea_talents_page extends $mol_page {
		
		/**
		 * ```tree
		 * domain $hyoo_idea_domain
		 * ```
		 */
		@ $mol_mem
		domain() {
			const obj = new this.$.$hyoo_idea_domain()
			
			return obj
		}
		
		/**
		 * ```tree
		 * title @ \Talent Search
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_idea_talents_page_title' )
		}
		
		/**
		 * ```tree
		 * tools / <= Close
		 * ```
		 */
		tools() {
			return [
				this.Close()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * persons /$hyoo_idea_person
		 * ```
		 */
		persons() {
			return [
			] as readonly $hyoo_idea_person[]
		}
		
		/**
		 * ```tree
		 * projects /$hyoo_idea_project
		 * ```
		 */
		projects() {
			return [
			] as readonly $hyoo_idea_project[]
		}
		
		/**
		 * ```tree
		 * body / <= Results
		 * ```
		 */
		body() {
			return [
				this.Results()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Person_row*0_0 $hyoo_idea_person_card person <= person*
		 * ```
		 */
		@ $mol_mem_key
		Person_row(id: any) {
			const obj = new this.$.$hyoo_idea_person_card()
			
			obj.person = () => this.person(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Close null
		 * ```
		 */
		Close() {
			return null as any
		}
		
		/**
		 * ```tree
		 * results /
		 * ```
		 */
		results() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Results $mol_list rows <= results
		 * ```
		 */
		@ $mol_mem
		Results() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.results()
			
			return obj
		}
		
		/**
		 * ```tree
		 * person* $hyoo_idea_person
		 * ```
		 */
		@ $mol_mem_key
		person(id: any) {
			const obj = new this.$.$hyoo_idea_person()
			
			return obj
		}
	}
	
}

