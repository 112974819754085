namespace $ {
	export class $hyoo_idea_app extends $mol_book2_catalog {
		
		/**
		 * ```tree
		 * yard
		 * ```
		 */
		yard() {
			return this.domain().yard()
		}
		
		/**
		 * ```tree
		 * user
		 * ```
		 */
		user() {
			return this.domain().user()
		}
		
		/**
		 * ```tree
		 * domain $hyoo_idea_domain
		 * 	yard => yard
		 * 	user => user
		 * ```
		 */
		@ $mol_mem
		domain() {
			const obj = new this.$.$hyoo_idea_domain()
			
			return obj
		}
		
		/**
		 * ```tree
		 * plugins / <= Theme
		 * ```
		 */
		plugins() {
			return [
				this.Theme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * menu_head /
		 * 	<= Menu_snippet
		 * 	<= Person_link
		 * ```
		 */
		menu_head() {
			return [
				this.Menu_snippet(),
				this.Person_link()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * menu_foot /
		 * 	<= Online
		 * 	<= Lights
		 * 	<= Source
		 * ```
		 */
		menu_foot() {
			return [
				this.Online(),
				this.Lights(),
				this.Source()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * spreads *
		 * 	news <= Feed
		 * 	projects <= Project_list
		 * 	talents <= Talents_page
		 * ```
		 */
		spreads() {
			return {
				news: this.Feed(),
				projects: this.Project_list(),
				talents: this.Talents_page()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Project_page* $hyoo_idea_project_page project <= project_opened
		 * ```
		 */
		@ $mol_mem_key
		Project_page(id: any) {
			const obj = new this.$.$hyoo_idea_project_page()
			
			obj.project = () => this.project_opened()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Person_page* $hyoo_idea_person_page person <= person_opened
		 * ```
		 */
		@ $mol_mem_key
		Person_page(id: any) {
			const obj = new this.$.$hyoo_idea_person_page()
			
			obj.person = () => this.person_opened()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Invite_page $hyoo_idea_invite_page domain <= domain
		 * ```
		 */
		@ $mol_mem
		Invite_page() {
			const obj = new this.$.$hyoo_idea_invite_page()
			
			obj.domain = () => this.domain()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Theme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Theme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Logo $mol_image uri \/hyoo/idea/logo/logo.svg
		 * ```
		 */
		@ $mol_mem
		Logo() {
			const obj = new this.$.$mol_image()
			
			obj.uri = () => "/hyoo/idea/logo/logo.svg"
			
			return obj
		}
		
		/**
		 * ```tree
		 * menu_title \$hyoo_idea
		 * ```
		 */
		menu_title() {
			return "$hyoo_idea"
		}
		
		/**
		 * ```tree
		 * Title $mol_paragraph title <= menu_title
		 * ```
		 */
		@ $mol_mem
		Title() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.menu_title()
			
			return obj
		}
		
		/**
		 * ```tree
		 * descr @ \Fresh Ideas Network
		 * ```
		 */
		descr() {
			return this.$.$mol_locale.text( '$hyoo_idea_app_descr' )
		}
		
		/**
		 * ```tree
		 * Descr $mol_paragraph title <= descr
		 * ```
		 */
		@ $mol_mem
		Descr() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.descr()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Label $mol_list rows /
		 * 	<= Title
		 * 	<= Descr
		 * ```
		 */
		@ $mol_mem
		Label() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Title(),
				this.Descr()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Menu_snippet $mol_view sub /
		 * 	<= Logo
		 * 	<= Label
		 * ```
		 */
		@ $mol_mem
		Menu_snippet() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Logo(),
				this.Label()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * user_id \0_0
		 * ```
		 */
		user_id() {
			return "0_0"
		}
		
		/**
		 * ```tree
		 * Person_icon $mol_icon_account
		 * ```
		 */
		@ $mol_mem
		Person_icon() {
			const obj = new this.$.$mol_icon_account()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Person_link $mol_link
		 * 	arg * person <= user_id
		 * 	hint @ \Profile
		 * 	sub / <= Person_icon
		 * ```
		 */
		@ $mol_mem
		Person_link() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				person: this.user_id()
			} as Record< string, any >)
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_app_Person_link_hint' )
			obj.sub = () => [
				this.Person_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Online $hyoo_sync_online yard <= yard
		 * ```
		 */
		@ $mol_mem
		Online() {
			const obj = new this.$.$hyoo_sync_online()
			
			obj.yard = () => this.yard()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Lights $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		Lights() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Source $mol_link_source uri \https://github.com/hyoo-ru/idea.hyoo.ru
		 * ```
		 */
		@ $mol_mem
		Source() {
			const obj = new this.$.$mol_link_source()
			
			obj.uri = () => "https://github.com/hyoo-ru/idea.hyoo.ru"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Feed $hyoo_idea_feed_page
		 * 	person <= user
		 * 	Close <= Spread_close
		 * ```
		 */
		@ $mol_mem
		Feed() {
			const obj = new this.$.$hyoo_idea_feed_page()
			
			obj.person = () => this.user()
			obj.Close = () => this.Spread_close()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Project_list $hyoo_idea_projects
		 * 	domain <= domain
		 * 	Close <= Spread_close
		 * ```
		 */
		@ $mol_mem
		Project_list() {
			const obj = new this.$.$hyoo_idea_projects()
			
			obj.domain = () => this.domain()
			obj.Close = () => this.Spread_close()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Talents_page $hyoo_idea_talents_page
		 * 	domain <= domain
		 * 	Close <= Spread_close
		 * ```
		 */
		@ $mol_mem
		Talents_page() {
			const obj = new this.$.$hyoo_idea_talents_page()
			
			obj.domain = () => this.domain()
			obj.Close = () => this.Spread_close()
			
			return obj
		}
		
		/**
		 * ```tree
		 * project_opened $hyoo_idea_project
		 * ```
		 */
		@ $mol_mem
		project_opened() {
			const obj = new this.$.$hyoo_idea_project()
			
			return obj
		}
		
		/**
		 * ```tree
		 * person_opened $hyoo_idea_person
		 * ```
		 */
		@ $mol_mem
		person_opened() {
			const obj = new this.$.$hyoo_idea_person()
			
			return obj
		}
	}
	
}

