namespace $ {
	export class $mol_icon_upload extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z
		 * ```
		 */
		path() {
			return "M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z"
		}
	}
	
}

