namespace $ {
	export class $hyoo_idea_invite_page extends $mol_page {
		
		/**
		 * ```tree
		 * domain $hyoo_idea_domain
		 * ```
		 */
		@ $mol_mem
		domain() {
			const obj = new this.$.$hyoo_idea_domain()
			
			return obj
		}
		
		/**
		 * ```tree
		 * title @ \Invite
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_idea_invite_page_title' )
		}
		
		/**
		 * ```tree
		 * tools / <= Close_head
		 * ```
		 */
		tools() {
			return [
				this.Close_head()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * status *
		 * 	sended @ \Invitation sent
		 * 	joined @ \The user is already on the project team
		 * 	none @ \None
		 * ```
		 */
		status() {
			return {
				sended: this.$.$mol_locale.text( '$hyoo_idea_invite_page_status_sended' ),
				joined: this.$.$mol_locale.text( '$hyoo_idea_invite_page_status_joined' ),
				none: this.$.$mol_locale.text( '$hyoo_idea_invite_page_status_none' )
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * body / <= Form
		 * ```
		 */
		body() {
			return [
				this.Form()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Close_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Close_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Close_head $mol_link
		 * 	arg *
		 * 		invite_project null
		 * 		invite_person null
		 * 	sub / <= Close_icon
		 * ```
		 */
		@ $mol_mem
		Close_head() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				invite_project: null as any,
				invite_person: null as any
			} as Record< string, any >)
			obj.sub = () => [
				this.Close_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * project_id? \
		 * ```
		 */
		@ $mol_mem
		project_id(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * project_dict *
		 * ```
		 */
		project_dict() {
			return {
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Project_select_control $mol_select
		 * 	value? <=> project_id?
		 * 	dictionary <= project_dict
		 * ```
		 */
		@ $mol_mem
		Project_select_control() {
			const obj = new this.$.$mol_select()
			
			obj.value = (next?: any) => this.project_id(next)
			obj.dictionary = () => this.project_dict()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Project_select_field $mol_form_field
		 * 	name @ \Select project
		 * 	control <= Project_select_control
		 * ```
		 */
		@ $mol_mem
		Project_select_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_invite_page_Project_select_field_name' )
			obj.control = () => this.Project_select_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * project $hyoo_idea_project
		 * ```
		 */
		@ $mol_mem
		project() {
			const obj = new this.$.$hyoo_idea_project()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Project_content $hyoo_idea_project_card
		 * 	project <= project
		 * 	brief \
		 * ```
		 */
		@ $mol_mem
		Project_content() {
			const obj = new this.$.$hyoo_idea_project_card()
			
			obj.project = () => this.project()
			obj.brief = () => ""
			
			return obj
		}
		
		/**
		 * ```tree
		 * Project_field $mol_form_field
		 * 	name @ \Project
		 * 	Content <= Project_content
		 * ```
		 */
		@ $mol_mem
		Project_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_invite_page_Project_field_name' )
			obj.Content = () => this.Project_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * person_id? \
		 * ```
		 */
		@ $mol_mem
		person_id(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * person_dict *
		 * ```
		 */
		person_dict() {
			return {
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Person_select_control $mol_select
		 * 	value? <=> person_id?
		 * 	dictionary <= person_dict
		 * ```
		 */
		@ $mol_mem
		Person_select_control() {
			const obj = new this.$.$mol_select()
			
			obj.value = (next?: any) => this.person_id(next)
			obj.dictionary = () => this.person_dict()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Person_select_field $mol_form_field
		 * 	name @ \Select person
		 * 	control <= Person_select_control
		 * ```
		 */
		@ $mol_mem
		Person_select_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_invite_page_Person_select_field_name' )
			obj.control = () => this.Person_select_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * person $hyoo_idea_person
		 * ```
		 */
		@ $mol_mem
		person() {
			const obj = new this.$.$hyoo_idea_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Person_content $hyoo_idea_person_card
		 * 	person <= person
		 * 	Actions null
		 * ```
		 */
		@ $mol_mem
		Person_content() {
			const obj = new this.$.$hyoo_idea_person_card()
			
			obj.person = () => this.person()
			obj.Actions = () => null as any
			
			return obj
		}
		
		/**
		 * ```tree
		 * Person_field $mol_form_field
		 * 	name @ \Person
		 * 	Content <= Person_content
		 * ```
		 */
		@ $mol_mem
		Person_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_invite_page_Person_field_name' )
			obj.Content = () => this.Person_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * status_text \
		 * ```
		 */
		status_text() {
			return ""
		}
		
		/**
		 * ```tree
		 * Statuc_content $mol_paragraph title <= status_text
		 * ```
		 */
		@ $mol_mem
		Statuc_content() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.status_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Status_field $mol_form_field
		 * 	name @ \Status
		 * 	Content <= Statuc_content
		 * ```
		 */
		@ $mol_mem
		Status_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_invite_page_Status_field_name' )
			obj.Content = () => this.Statuc_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * fields /
		 * 	<= Project_select_field
		 * 	<= Project_field
		 * 	<= Person_select_field
		 * 	<= Person_field
		 * 	<= Status_field
		 * ```
		 */
		fields() {
			return [
				this.Project_select_field(),
				this.Project_field(),
				this.Person_select_field(),
				this.Person_field(),
				this.Status_field()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * submit? null
		 * ```
		 */
		@ $mol_mem
		submit(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * submit_enabled true
		 * ```
		 */
		submit_enabled() {
			return true
		}
		
		/**
		 * ```tree
		 * Submit $mol_button_major
		 * 	title @ \Invite
		 * 	click? <=> submit?
		 * 	enabled <= submit_enabled
		 * ```
		 */
		@ $mol_mem
		Submit() {
			const obj = new this.$.$mol_button_major()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_invite_page_Submit_title' )
			obj.click = (next?: any) => this.submit(next)
			obj.enabled = () => this.submit_enabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Close $mol_link
		 * 	arg *
		 * 		invite_project null
		 * 		invite_person null
		 * 	title @ \Close
		 * ```
		 */
		@ $mol_mem
		Close() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				invite_project: null as any,
				invite_person: null as any
			} as Record< string, any >)
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_invite_page_Close_title' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * Form $mol_form
		 * 	form_fields <= fields
		 * 	buttons /
		 * 		<= Submit
		 * 		<= Close
		 * ```
		 */
		@ $mol_mem
		Form() {
			const obj = new this.$.$mol_form()
			
			obj.form_fields = () => this.fields()
			obj.buttons = () => [
				this.Submit(),
				this.Close()
			] as readonly any[]
			
			return obj
		}
	}
	
}

