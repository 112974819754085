namespace $ {
	export class $hyoo_idea_project_logo extends $hyoo_idea_person_avatar {
		
		/**
		 * ```tree
		 * placeholder \/hyoo/idea/project/logo/logo.svg
		 * ```
		 */
		placeholder() {
			return "/hyoo/idea/project/logo/logo.svg"
		}
	}
	
}

