namespace $.$$ {

	const { rem } = $mol_style_unit

	$mol_style_define( $.$hyoo_idea_invite_page, {

		flex: {
			basis: rem(30),
			shrink: 0,
		},

	} )

}
