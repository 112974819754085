namespace $ {
	export class $hyoo_idea_person_form extends $mol_form {
		
		/**
		 * ```tree
		 * avatar_node
		 * ```
		 */
		avatar_node() {
			return this.person().avatar_node()
		}
		
		/**
		 * ```tree
		 * status?
		 * ```
		 */
		status(next?: any) {
			return this.person().status(next)
		}
		
		/**
		 * ```tree
		 * about?
		 * ```
		 */
		about(next?: any) {
			return this.person().about(next)
		}
		
		/**
		 * ```tree
		 * name?
		 * ```
		 */
		name(next?: any) {
			return this.person().name(next)
		}
		
		/**
		 * ```tree
		 * country?
		 * ```
		 */
		country(next?: any) {
			return this.person().country(next)
		}
		
		/**
		 * ```tree
		 * city?
		 * ```
		 */
		city(next?: any) {
			return this.person().city(next)
		}
		
		/**
		 * ```tree
		 * phone?
		 * ```
		 */
		phone(next?: any) {
			return this.person().phone(next)
		}
		
		/**
		 * ```tree
		 * email?
		 * ```
		 */
		email(next?: any) {
			return this.person().email(next)
		}
		
		/**
		 * ```tree
		 * job_status?
		 * ```
		 */
		job_status(next?: any) {
			return this.person().job_status(next)
		}
		
		/**
		 * ```tree
		 * skills?
		 * ```
		 */
		skills(next?: any) {
			return this.person().skills(next)
		}
		
		/**
		 * ```tree
		 * person $hyoo_idea_person
		 * 	avatar_node => avatar_node
		 * 	status? => status?
		 * 	about? => about?
		 * 	name? => name?
		 * 	country? => country?
		 * 	city? => city?
		 * 	phone? => phone?
		 * 	email? => email?
		 * 	job_status? => job_status?
		 * 	skills? => skills?
		 * ```
		 */
		@ $mol_mem
		person() {
			const obj = new this.$.$hyoo_idea_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * msg * required @ \Required
		 * ```
		 */
		msg() {
			return {
				required: this.$.$mol_locale.text( '$hyoo_idea_person_form_msg_required' )
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * form_fields / <= Deck
		 * ```
		 */
		form_fields() {
			return [
				this.Deck()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * avatar_file? /
		 * ```
		 */
		@ $mol_mem
		avatar_file(next?: any) {
			if ( next !== undefined ) return next as never
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Avatar_upload $mol_button_open
		 * 	files? <=> avatar_file?
		 * 	multiple false
		 * 	accept \image/*
		 * ```
		 */
		@ $mol_mem
		Avatar_upload() {
			const obj = new this.$.$mol_button_open()
			
			obj.files = (next?: any) => this.avatar_file(next)
			obj.multiple = () => false
			obj.accept = () => "image/*"
			
			return obj
		}
		
		/**
		 * ```tree
		 * avatar_drop? null
		 * ```
		 */
		@ $mol_mem
		avatar_drop(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Avatar_drop_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Avatar_drop_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Avatar_drop $mol_button_minor
		 * 	hint @ \Remove avatar
		 * 	click? <=> avatar_drop?
		 * 	sub / <= Avatar_drop_icon
		 * ```
		 */
		@ $mol_mem
		Avatar_drop() {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Avatar_drop_hint' )
			obj.click = (next?: any) => this.avatar_drop(next)
			obj.sub = () => [
				this.Avatar_drop_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Avatar_control $mol_view sub /
		 * 	<= Avatar_upload
		 * 	<= Avatar_drop
		 * ```
		 */
		@ $mol_mem
		Avatar_control() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Avatar_upload(),
				this.Avatar_drop()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Avatar_field $mol_form_field
		 * 	name @ \Avatar
		 * 	control <= Avatar_control
		 * ```
		 */
		@ $mol_mem
		Avatar_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Avatar_field_name' )
			obj.control = () => this.Avatar_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_control $mol_string
		 * 	hint @ \Thomas Andreson
		 * 	value? <=> name?
		 * ```
		 */
		@ $mol_mem
		Name_control() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Name_control_hint' )
			obj.value = (next?: any) => this.name(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_field $mol_form_field
		 * 	name @ \Name
		 * 	control <= Name_control
		 * ```
		 */
		@ $mol_mem
		Name_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Name_field_name' )
			obj.control = () => this.Name_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Status_control $mol_string
		 * 	hint @ \What are you doing?
		 * 	value? <=> status?
		 * ```
		 */
		@ $mol_mem
		Status_control() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Status_control_hint' )
			obj.value = (next?: any) => this.status(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Status_field $mol_form_field
		 * 	name @ \Status
		 * 	control <= Status_control
		 * ```
		 */
		@ $mol_mem
		Status_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Status_field_name' )
			obj.control = () => this.Status_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_group $mol_form_group sub /
		 * 	<= Name_field
		 * 	<= Status_field
		 * ```
		 */
		@ $mol_mem
		Name_group() {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Name_field(),
				this.Status_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * About_control $mol_textarea
		 * 	hint @ \Tell us about yourself
		 * 	value? <=> about?
		 * ```
		 */
		@ $mol_mem
		About_control() {
			const obj = new this.$.$mol_textarea()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_About_control_hint' )
			obj.value = (next?: any) => this.about(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * About_field $mol_form_field
		 * 	name @ \About
		 * 	control <= About_control
		 * ```
		 */
		@ $mol_mem
		About_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_About_field_name' )
			obj.control = () => this.About_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Country_control $mol_string
		 * 	hint @ \Russia
		 * 	value? <=> country?
		 * ```
		 */
		@ $mol_mem
		Country_control() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Country_control_hint' )
			obj.value = (next?: any) => this.country(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Country_field $mol_form_field
		 * 	name @ \Country
		 * 	control <= Country_control
		 * ```
		 */
		@ $mol_mem
		Country_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Country_field_name' )
			obj.control = () => this.Country_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * City_control $mol_string
		 * 	hint @ \Moscow
		 * 	value? <=> city?
		 * ```
		 */
		@ $mol_mem
		City_control() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_City_control_hint' )
			obj.value = (next?: any) => this.city(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * City_field $mol_form_field
		 * 	name @ \City
		 * 	control <= City_control
		 * ```
		 */
		@ $mol_mem
		City_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_City_field_name' )
			obj.control = () => this.City_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Location $mol_form_group
		 * 	title @ \Location
		 * 	sub /
		 * 		<= Country_field
		 * 		<= City_field
		 * ```
		 */
		@ $mol_mem
		Location() {
			const obj = new this.$.$mol_form_group()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Location_title' )
			obj.sub = () => [
				this.Country_field(),
				this.City_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Phone_control $mol_phone value? <=> phone?
		 * ```
		 */
		@ $mol_mem
		Phone_control() {
			const obj = new this.$.$mol_phone()
			
			obj.value = (next?: any) => this.phone(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Phone_field $mol_form_field
		 * 	name @ \Phone
		 * 	control <= Phone_control
		 * ```
		 */
		@ $mol_mem
		Phone_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Phone_field_name' )
			obj.control = () => this.Phone_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Email_control $mol_string
		 * 	hint @ \my_email@mail.ru
		 * 	value? <=> email?
		 * ```
		 */
		@ $mol_mem
		Email_control() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Email_control_hint' )
			obj.value = (next?: any) => this.email(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Email_field $mol_form_field
		 * 	name @ \Email
		 * 	control <= Email_control
		 * ```
		 */
		@ $mol_mem
		Email_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Email_field_name' )
			obj.control = () => this.Email_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Contacts $mol_form_group
		 * 	title @ \Contacts
		 * 	sub /
		 * 		<= Phone_field
		 * 		<= Email_field
		 * ```
		 */
		@ $mol_mem
		Contacts() {
			const obj = new this.$.$mol_form_group()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Contacts_title' )
			obj.sub = () => [
				this.Phone_field(),
				this.Email_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Profile $mol_form
		 * 	title @ \Profile
		 * 	form_fields /
		 * 		<= Avatar_field
		 * 		<= Name_group
		 * 		<= About_field
		 * 		<= Location
		 * 		<= Contacts
		 * ```
		 */
		@ $mol_mem
		Profile() {
			const obj = new this.$.$mol_form()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Profile_title' )
			obj.form_fields = () => [
				this.Avatar_field(),
				this.Name_group(),
				this.About_field(),
				this.Location(),
				this.Contacts()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Job_status_control $mol_switch
		 * 	value? <=> job_status?
		 * 	options *
		 * 		working_for_hire @ \Working for hire
		 * 		self_employed @ \Self-employed
		 * 		unemployed @ \Unemployed
		 * ```
		 */
		@ $mol_mem
		Job_status_control() {
			const obj = new this.$.$mol_switch()
			
			obj.value = (next?: any) => this.job_status(next)
			obj.options = () => ({
				working_for_hire: this.$.$mol_locale.text( '$hyoo_idea_person_form_Job_status_control_options_working_for_hire' ),
				self_employed: this.$.$mol_locale.text( '$hyoo_idea_person_form_Job_status_control_options_self_employed' ),
				unemployed: this.$.$mol_locale.text( '$hyoo_idea_person_form_Job_status_control_options_unemployed' )
			} as Record< string, any >)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Job_status_field $mol_form_field
		 * 	name @ \Job status
		 * 	control <= Job_status_control
		 * ```
		 */
		@ $mol_mem
		Job_status_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Job_status_field_name' )
			obj.control = () => this.Job_status_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * skills_dict *
		 * 	programming @ \Programming
		 * 	design @ \Design
		 * 	managment @ \Managment
		 * ```
		 */
		skills_dict() {
			return {
				programming: this.$.$mol_locale.text( '$hyoo_idea_person_form_skills_dict_programming' ),
				design: this.$.$mol_locale.text( '$hyoo_idea_person_form_skills_dict_design' ),
				managment: this.$.$mol_locale.text( '$hyoo_idea_person_form_skills_dict_managment' )
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Skills_control $mol_select_list
		 * 	value? <=> skills?
		 * 	dictionary <= skills_dict
		 * ```
		 */
		@ $mol_mem
		Skills_control() {
			const obj = new this.$.$mol_select_list()
			
			obj.value = (next?: any) => this.skills(next)
			obj.dictionary = () => this.skills_dict()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Skills_field $mol_form_field
		 * 	name @ \Skills
		 * 	control <= Skills_control
		 * ```
		 */
		@ $mol_mem
		Skills_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Skills_field_name' )
			obj.control = () => this.Skills_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * jobs_field_name @ \Experience
		 * ```
		 */
		jobs_field_name() {
			return this.$.$mol_locale.text( '$hyoo_idea_person_form_jobs_field_name' )
		}
		
		/**
		 * ```tree
		 * Job_add_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		Job_add_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * job_add? null
		 * ```
		 */
		@ $mol_mem
		job_add(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Job_add $mol_button_minor
		 * 	sub / <= Job_add_icon
		 * 	click? <=> job_add?
		 * ```
		 */
		@ $mol_mem
		Job_add() {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Job_add_icon()
			] as readonly any[]
			obj.click = (next?: any) => this.job_add(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * position*? \
		 * ```
		 */
		@ $mol_mem_key
		position(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Position_control* $mol_string value? <=> position*?
		 * ```
		 */
		@ $mol_mem_key
		Position_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.position(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Position_field* $mol_form_field
		 * 	name @ \Position
		 * 	control <= Position_control*
		 * ```
		 */
		@ $mol_mem_key
		Position_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Position_field_name' )
			obj.control = () => this.Position_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Employer_group* $mol_form_group sub / <= Position_field*
		 * ```
		 */
		@ $mol_mem_key
		Employer_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Position_field(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * date_start*? null
		 * ```
		 */
		@ $mol_mem_key
		date_start(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Date_start_control* $mol_date value_moment? <=> date_start*?
		 * ```
		 */
		@ $mol_mem_key
		Date_start_control(id: any) {
			const obj = new this.$.$mol_date()
			
			obj.value_moment = (next?: any) => this.date_start(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Date_start_field* $mol_form_field
		 * 	name @ \Start date
		 * 	control <= Date_start_control*
		 * ```
		 */
		@ $mol_mem_key
		Date_start_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Date_start_field_name' )
			obj.control = () => this.Date_start_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * date_end*? null
		 * ```
		 */
		@ $mol_mem_key
		date_end(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Date_end_control* $mol_date value_moment? <=> date_end*?
		 * ```
		 */
		@ $mol_mem_key
		Date_end_control(id: any) {
			const obj = new this.$.$mol_date()
			
			obj.value_moment = (next?: any) => this.date_end(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * present*? false
		 * ```
		 */
		@ $mol_mem_key
		present(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Up_to_present_control* $mol_check_box
		 * 	title @ \Up to present
		 * 	checked? <=> present*?
		 * ```
		 */
		@ $mol_mem_key
		Up_to_present_control(id: any) {
			const obj = new this.$.$mol_check_box()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Up_to_present_control_title' )
			obj.checked = (next?: any) => this.present(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Date_end_content* $mol_view sub /
		 * 	<= Date_end_control*
		 * 	<= Up_to_present_control*
		 * ```
		 */
		@ $mol_mem_key
		Date_end_content(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Date_end_control(id),
				this.Up_to_present_control(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Date_end_field* $mol_form_field
		 * 	name @ \End date
		 * 	Content <= Date_end_content*
		 * ```
		 */
		@ $mol_mem_key
		Date_end_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Date_end_field_name' )
			obj.Content = () => this.Date_end_content(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Date_group* $mol_form_group sub /
		 * 	<= Date_start_field*
		 * 	<= Date_end_field*
		 * ```
		 */
		@ $mol_mem_key
		Date_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Date_start_field(id),
				this.Date_end_field(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * company*? \
		 * ```
		 */
		@ $mol_mem_key
		company(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Company_control* $mol_string value? <=> company*?
		 * ```
		 */
		@ $mol_mem_key
		Company_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.company(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Company_field* $mol_form_field
		 * 	name @ \Company
		 * 	control <= Company_control*
		 * ```
		 */
		@ $mol_mem_key
		Company_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Company_field_name' )
			obj.control = () => this.Company_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * industry*? \
		 * ```
		 */
		@ $mol_mem_key
		industry(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Industry_contrl* $mol_string value? <=> industry*?
		 * ```
		 */
		@ $mol_mem_key
		Industry_contrl(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.industry(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Industry_field* $mol_form_field
		 * 	name @ \Industry
		 * 	control <= Industry_contrl*
		 * ```
		 */
		@ $mol_mem_key
		Industry_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Industry_field_name' )
			obj.control = () => this.Industry_contrl(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Company_group* $mol_form_group sub /
		 * 	<= Company_field*
		 * 	<= Industry_field*
		 * ```
		 */
		@ $mol_mem_key
		Company_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Company_field(id),
				this.Industry_field(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * functions*? \
		 * ```
		 */
		@ $mol_mem_key
		functions(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Functions_control* $mol_textarea
		 * 	hint @ \Your job functions
		 * 	value? <=> functions*?
		 * ```
		 */
		@ $mol_mem_key
		Functions_control(id: any) {
			const obj = new this.$.$mol_textarea()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Functions_control_hint' )
			obj.value = (next?: any) => this.functions(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Functions_field* $mol_form_field
		 * 	name @ \Functions
		 * 	control <= Functions_control*
		 * ```
		 */
		@ $mol_mem_key
		Functions_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Functions_field_name' )
			obj.control = () => this.Functions_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Job_drop_icon* $mol_icon_delete
		 * ```
		 */
		@ $mol_mem_key
		Job_drop_icon(id: any) {
			const obj = new this.$.$mol_icon_delete()
			
			return obj
		}
		
		/**
		 * ```tree
		 * job_drop*? null
		 * ```
		 */
		@ $mol_mem_key
		job_drop(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Job_drop* $mol_button_minor
		 * 	sub / <= Job_drop_icon*
		 * 	click? <=> job_drop*?
		 * ```
		 */
		@ $mol_mem_key
		Job_drop(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Job_drop_icon(id)
			] as readonly any[]
			obj.click = (next?: any) => this.job_drop(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Job_drop_row* $mol_row sub / <= Job_drop*
		 * ```
		 */
		@ $mol_mem_key
		Job_drop_row(id: any) {
			const obj = new this.$.$mol_row()
			
			obj.sub = () => [
				this.Job_drop(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Job_form*0 $mol_list rows /
		 * 	<= Employer_group*
		 * 	<= Date_group*
		 * 	<= Company_group*
		 * 	<= Functions_field*
		 * 	<= Job_drop_row*
		 * ```
		 */
		@ $mol_mem_key
		Job_form(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Employer_group(id),
				this.Date_group(id),
				this.Company_group(id),
				this.Functions_field(id),
				this.Job_drop_row(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * job_rows / <= Job_form*0
		 * ```
		 */
		job_rows() {
			return [
				this.Job_form("0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Jobs_content $mol_list rows <= job_rows
		 * ```
		 */
		@ $mol_mem
		Jobs_content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.job_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Jobs_field $mol_form_field
		 * 	label /
		 * 		<= jobs_field_name
		 * 		<= Job_add
		 * 	Content <= Jobs_content
		 * ```
		 */
		@ $mol_mem
		Jobs_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.label = () => [
				this.jobs_field_name(),
				this.Job_add()
			] as readonly any[]
			obj.Content = () => this.Jobs_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Work $mol_form
		 * 	title @ \Work
		 * 	form_fields /
		 * 		<= Job_status_field
		 * 		<= Skills_field
		 * 		<= Jobs_field
		 * ```
		 */
		@ $mol_mem
		Work() {
			const obj = new this.$.$mol_form()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Work_title' )
			obj.form_fields = () => [
				this.Job_status_field(),
				this.Skills_field(),
				this.Jobs_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * institutions_field_name @ \Institutions
		 * ```
		 */
		institutions_field_name() {
			return this.$.$mol_locale.text( '$hyoo_idea_person_form_institutions_field_name' )
		}
		
		/**
		 * ```tree
		 * Institution_add_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		Institution_add_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * institution_add? null
		 * ```
		 */
		@ $mol_mem
		institution_add(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Institution_add $mol_button_minor
		 * 	sub / <= Institution_add_icon
		 * 	click? <=> institution_add?
		 * ```
		 */
		@ $mol_mem
		Institution_add() {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Institution_add_icon()
			] as readonly any[]
			obj.click = (next?: any) => this.institution_add(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * specialty*? \
		 * ```
		 */
		@ $mol_mem_key
		specialty(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Specialty_control* $mol_string value? <=> specialty*?
		 * ```
		 */
		@ $mol_mem_key
		Specialty_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.specialty(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Specialty_field* $mol_form_field
		 * 	name @ \Specialty
		 * 	control <= Specialty_control*
		 * ```
		 */
		@ $mol_mem_key
		Specialty_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Specialty_field_name' )
			obj.control = () => this.Specialty_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * degree*? \
		 * ```
		 */
		@ $mol_mem_key
		degree(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Degree_control* $mol_string value? <=> degree*?
		 * ```
		 */
		@ $mol_mem_key
		Degree_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.degree(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Degree_field* $mol_form_field
		 * 	name @ \Degree
		 * 	control <= Degree_control*
		 * ```
		 */
		@ $mol_mem_key
		Degree_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Degree_field_name' )
			obj.control = () => this.Degree_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Student_group* $mol_form_group sub /
		 * 	<= Specialty_field*
		 * 	<= Degree_field*
		 * ```
		 */
		@ $mol_mem_key
		Student_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Specialty_field(id),
				this.Degree_field(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * date_finish*? null
		 * ```
		 */
		@ $mol_mem_key
		date_finish(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Date_finish_control* $mol_date value_moment? <=> date_finish*?
		 * ```
		 */
		@ $mol_mem_key
		Date_finish_control(id: any) {
			const obj = new this.$.$mol_date()
			
			obj.value_moment = (next?: any) => this.date_finish(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Date_finish* $mol_form_field
		 * 	name @ \Graduation date
		 * 	control <= Date_finish_control*
		 * ```
		 */
		@ $mol_mem_key
		Date_finish(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Date_finish_name' )
			obj.control = () => this.Date_finish_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * institution*? \
		 * ```
		 */
		@ $mol_mem_key
		institution(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Institution_control* $mol_string value? <=> institution*?
		 * ```
		 */
		@ $mol_mem_key
		Institution_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.institution(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institution_field* $mol_form_field
		 * 	name @ \Institution
		 * 	control <= Institution_control*
		 * ```
		 */
		@ $mol_mem_key
		Institution_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Institution_field_name' )
			obj.control = () => this.Institution_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * department*? \
		 * ```
		 */
		@ $mol_mem_key
		department(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Department_control* $mol_string value? <=> department*?
		 * ```
		 */
		@ $mol_mem_key
		Department_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.department(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Department_field* $mol_form_field
		 * 	name @ \Department
		 * 	control <= Department_control*
		 * ```
		 */
		@ $mol_mem_key
		Department_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Department_field_name' )
			obj.control = () => this.Department_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institution_group* $mol_form_group sub /
		 * 	<= Institution_field*
		 * 	<= Department_field*
		 * ```
		 */
		@ $mol_mem_key
		Institution_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Institution_field(id),
				this.Department_field(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institution_drop_icon* $mol_icon_delete
		 * ```
		 */
		@ $mol_mem_key
		Institution_drop_icon(id: any) {
			const obj = new this.$.$mol_icon_delete()
			
			return obj
		}
		
		/**
		 * ```tree
		 * institution_drop*? null
		 * ```
		 */
		@ $mol_mem_key
		institution_drop(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Institution_drop* $mol_button_minor
		 * 	sub / <= Institution_drop_icon*
		 * 	click? <=> institution_drop*?
		 * ```
		 */
		@ $mol_mem_key
		Institution_drop(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Institution_drop_icon(id)
			] as readonly any[]
			obj.click = (next?: any) => this.institution_drop(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institution_drop_row* $mol_row sub / <= Institution_drop*
		 * ```
		 */
		@ $mol_mem_key
		Institution_drop_row(id: any) {
			const obj = new this.$.$mol_row()
			
			obj.sub = () => [
				this.Institution_drop(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institution_form*0 $mol_list rows /
		 * 	<= Student_group*
		 * 	<= Date_finish*
		 * 	<= Institution_group*
		 * 	<= Institution_drop_row*
		 * ```
		 */
		@ $mol_mem_key
		Institution_form(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Student_group(id),
				this.Date_finish(id),
				this.Institution_group(id),
				this.Institution_drop_row(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * institution_rows / <= Institution_form*0
		 * ```
		 */
		institution_rows() {
			return [
				this.Institution_form("0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Institution_content $mol_list rows <= institution_rows
		 * ```
		 */
		@ $mol_mem
		Institution_content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.institution_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institutions_field $mol_form_field
		 * 	label /
		 * 		<= institutions_field_name
		 * 		<= Institution_add
		 * 	Content <= Institution_content
		 * ```
		 */
		@ $mol_mem
		Institutions_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.label = () => [
				this.institutions_field_name(),
				this.Institution_add()
			] as readonly any[]
			obj.Content = () => this.Institution_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Education $mol_form
		 * 	title @ \Education
		 * 	form_fields / <= Institutions_field
		 * ```
		 */
		@ $mol_mem
		Education() {
			const obj = new this.$.$mol_form()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_person_form_Education_title' )
			obj.form_fields = () => [
				this.Institutions_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Deck $mol_deck items /
		 * 	<= Profile
		 * 	<= Work
		 * 	<= Education
		 * ```
		 */
		@ $mol_mem
		Deck() {
			const obj = new this.$.$mol_deck()
			
			obj.items = () => [
				this.Profile(),
				this.Work(),
				this.Education()
			] as readonly any[]
			
			return obj
		}
	}
	
}

