namespace $ {
	export class $mol_icon_check extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z
		 * ```
		 */
		path() {
			return "M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
		}
	}
	
}

