namespace $ {
	export class $hyoo_idea_project_form extends $mol_form {
		
		/**
		 * ```tree
		 * name?
		 * ```
		 */
		name(next?: any) {
			return this.project().name(next)
		}
		
		/**
		 * ```tree
		 * brief?
		 * ```
		 */
		brief(next?: any) {
			return this.project().brief(next)
		}
		
		/**
		 * ```tree
		 * site?
		 * ```
		 */
		site(next?: any) {
			return this.project().site(next)
		}
		
		/**
		 * ```tree
		 * logo_node
		 * ```
		 */
		logo_node() {
			return this.project().logo_node()
		}
		
		/**
		 * ```tree
		 * description_node
		 * ```
		 */
		description_node() {
			return this.project().description_node()
		}
		
		/**
		 * ```tree
		 * description?
		 * ```
		 */
		description(next?: any) {
			return this.project().description(next)
		}
		
		/**
		 * ```tree
		 * stage?
		 * ```
		 */
		stage(next?: any) {
			return this.project().stage(next)
		}
		
		/**
		 * ```tree
		 * team?
		 * ```
		 */
		team(next?: any) {
			return this.project().team(next)
		}
		
		/**
		 * ```tree
		 * project $hyoo_idea_project
		 * 	name? => name?
		 * 	brief? => brief?
		 * 	site? => site?
		 * 	logo_node => logo_node
		 * 	description_node => description_node
		 * 	description? => description?
		 * 	stage? => stage?
		 * 	team? => team?
		 * ```
		 */
		@ $mol_mem
		project() {
			const obj = new this.$.$hyoo_idea_project()
			
			return obj
		}
		
		/**
		 * ```tree
		 * role_not_label @ \No role
		 * ```
		 */
		role_not_label() {
			return this.$.$mol_locale.text( '$hyoo_idea_project_form_role_not_label' )
		}
		
		/**
		 * ```tree
		 * form_fields /
		 * 	<= Main_group
		 * 	<= Addon_group
		 * 	<= Stage_field
		 * 	<= Description_field
		 * 	<= Roles_field
		 * 	<= Team_field
		 * 	<= Requests_field
		 * 	<= Invites_field
		 * ```
		 */
		form_fields() {
			return [
				this.Main_group(),
				this.Addon_group(),
				this.Stage_field(),
				this.Description_field(),
				this.Roles_field(),
				this.Team_field(),
				this.Requests_field(),
				this.Invites_field()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Name_control $mol_string value? <=> name?
		 * ```
		 */
		@ $mol_mem
		Name_control() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.name(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_field $mol_form_field
		 * 	name @ \Name
		 * 	control <= Name_control
		 * ```
		 */
		@ $mol_mem
		Name_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Name_field_name' )
			obj.control = () => this.Name_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * logo_add? /
		 * ```
		 */
		@ $mol_mem
		logo_add(next?: any) {
			if ( next !== undefined ) return next as never
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Logo_upload $mol_button_open
		 * 	files? <=> logo_add?
		 * 	multiple false
		 * 	accept \image/*
		 * ```
		 */
		@ $mol_mem
		Logo_upload() {
			const obj = new this.$.$mol_button_open()
			
			obj.files = (next?: any) => this.logo_add(next)
			obj.multiple = () => false
			obj.accept = () => "image/*"
			
			return obj
		}
		
		/**
		 * ```tree
		 * logo_drop? null
		 * ```
		 */
		@ $mol_mem
		logo_drop(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Logo_drop_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Logo_drop_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Logo_drop $mol_button_minor
		 * 	hint @ \Remove logo
		 * 	click? <=> logo_drop?
		 * 	sub / <= Logo_drop_icon
		 * ```
		 */
		@ $mol_mem
		Logo_drop() {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Logo_drop_hint' )
			obj.click = (next?: any) => this.logo_drop(next)
			obj.sub = () => [
				this.Logo_drop_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Logo_control $mol_bar sub /
		 * 	<= Logo_upload
		 * 	<= Logo_drop
		 * ```
		 */
		@ $mol_mem
		Logo_control() {
			const obj = new this.$.$mol_bar()
			
			obj.sub = () => [
				this.Logo_upload(),
				this.Logo_drop()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Logo_field $mol_form_field
		 * 	name @ \Logo
		 * 	control <= Logo_control
		 * ```
		 */
		@ $mol_mem
		Logo_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Logo_field_name' )
			obj.control = () => this.Logo_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Main_group $mol_form_group sub /
		 * 	<= Name_field
		 * 	<= Logo_field
		 * ```
		 */
		@ $mol_mem
		Main_group() {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Name_field(),
				this.Logo_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Brief_control $mol_string value? <=> brief?
		 * ```
		 */
		@ $mol_mem
		Brief_control() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.brief(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Brief_field $mol_form_field
		 * 	name @ \Brief
		 * 	control <= Brief_control
		 * ```
		 */
		@ $mol_mem
		Brief_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Brief_field_name' )
			obj.control = () => this.Brief_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Site_control $mol_string value? <=> site?
		 * ```
		 */
		@ $mol_mem
		Site_control() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.site(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Site_field $mol_form_field
		 * 	name @ \Site
		 * 	control <= Site_control
		 * ```
		 */
		@ $mol_mem
		Site_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Site_field_name' )
			obj.control = () => this.Site_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Addon_group $mol_form_group sub /
		 * 	<= Brief_field
		 * 	<= Site_field
		 * ```
		 */
		@ $mol_mem
		Addon_group() {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Brief_field(),
				this.Site_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * stage_options *
		 * ```
		 */
		stage_options() {
			return {
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Stage_control $mol_switch
		 * 	value? <=> stage?
		 * 	options <= stage_options
		 * ```
		 */
		@ $mol_mem
		Stage_control() {
			const obj = new this.$.$mol_switch()
			
			obj.value = (next?: any) => this.stage(next)
			obj.options = () => this.stage_options()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Stage_field $mol_form_field
		 * 	name @ \Stage
		 * 	control <= Stage_control
		 * ```
		 */
		@ $mol_mem
		Stage_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Stage_field_name' )
			obj.control = () => this.Stage_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * description_selection? /number
		 * ```
		 */
		@ $mol_mem
		description_selection(next?: any) {
			if ( next !== undefined ) return next as never
			return [
			] as readonly number[]
		}
		
		/**
		 * ```tree
		 * Description_control $mol_textarea
		 * 	value? <=> description?
		 * 	selection? <=> description_selection?
		 * ```
		 */
		@ $mol_mem
		Description_control() {
			const obj = new this.$.$mol_textarea()
			
			obj.value = (next?: any) => this.description(next)
			obj.selection = (next?: any) => this.description_selection(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Description_field $mol_form_field
		 * 	name @ \Description 
		 * 	control <= Description_control
		 * ```
		 */
		@ $mol_mem
		Description_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Description_field_name' )
			obj.control = () => this.Description_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * roles_field_name @ \Roles
		 * ```
		 */
		roles_field_name() {
			return this.$.$mol_locale.text( '$hyoo_idea_project_form_roles_field_name' )
		}
		
		/**
		 * ```tree
		 * Role_add_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		Role_add_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * role_add? null
		 * ```
		 */
		@ $mol_mem
		role_add(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Role_add $mol_button_minor
		 * 	sub / <= Role_add_icon
		 * 	click? <=> role_add?
		 * ```
		 */
		@ $mol_mem
		Role_add() {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Role_add_icon()
			] as readonly any[]
			obj.click = (next?: any) => this.role_add(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * role_name*? \
		 * ```
		 */
		@ $mol_mem_key
		role_name(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Role_name_control* $mol_string value? <=> role_name*?
		 * ```
		 */
		@ $mol_mem_key
		Role_name_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.role_name(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Role_name_field* $mol_form_field
		 * 	name @ \Role name
		 * 	control <= Role_name_control*
		 * ```
		 */
		@ $mol_mem_key
		Role_name_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Role_name_field_name' )
			obj.control = () => this.Role_name_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * role_functions*? \
		 * ```
		 */
		@ $mol_mem_key
		role_functions(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Role_functions_control* $mol_textarea value? <=> role_functions*?
		 * ```
		 */
		@ $mol_mem_key
		Role_functions_control(id: any) {
			const obj = new this.$.$mol_textarea()
			
			obj.value = (next?: any) => this.role_functions(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Role_functions_field* $mol_form_field
		 * 	name @ \Functions
		 * 	control <= Role_functions_control*
		 * ```
		 */
		@ $mol_mem_key
		Role_functions_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Role_functions_field_name' )
			obj.control = () => this.Role_functions_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Role_head_group* $mol_form_group sub /
		 * 	<= Role_name_field*
		 * 	<= Role_functions_field*
		 * ```
		 */
		@ $mol_mem_key
		Role_head_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Role_name_field(id),
				this.Role_functions_field(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * role_team_member*? \
		 * ```
		 */
		@ $mol_mem_key
		role_team_member(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * team_member_dict *
		 * ```
		 */
		team_member_dict() {
			return {
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Role_team_member_control* $mol_select
		 * 	value? <=> role_team_member*?
		 * 	dictionary <= team_member_dict
		 * ```
		 */
		@ $mol_mem_key
		Role_team_member_control(id: any) {
			const obj = new this.$.$mol_select()
			
			obj.value = (next?: any) => this.role_team_member(id, next)
			obj.dictionary = () => this.team_member_dict()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Role_team_member_field* $mol_form_field
		 * 	name @ \Team member
		 * 	control <= Role_team_member_control*
		 * ```
		 */
		@ $mol_mem_key
		Role_team_member_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Role_team_member_field_name' )
			obj.control = () => this.Role_team_member_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Role_drop_icon* $mol_icon_delete
		 * ```
		 */
		@ $mol_mem_key
		Role_drop_icon(id: any) {
			const obj = new this.$.$mol_icon_delete()
			
			return obj
		}
		
		/**
		 * ```tree
		 * role_drop*? null
		 * ```
		 */
		@ $mol_mem_key
		role_drop(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Role_drop* $mol_button_minor
		 * 	sub / <= Role_drop_icon*
		 * 	click? <=> role_drop*?
		 * ```
		 */
		@ $mol_mem_key
		Role_drop(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Role_drop_icon(id)
			] as readonly any[]
			obj.click = (next?: any) => this.role_drop(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Role_drop_row* $mol_row sub / <= Role_drop*
		 * ```
		 */
		@ $mol_mem_key
		Role_drop_row(id: any) {
			const obj = new this.$.$mol_row()
			
			obj.sub = () => [
				this.Role_drop(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Role_body_group* $mol_form_group sub /
		 * 	<= Role_team_member_field*
		 * 	<= Role_drop_row*
		 * ```
		 */
		@ $mol_mem_key
		Role_body_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Role_team_member_field(id),
				this.Role_drop_row(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Role_form*0 $mol_list rows /
		 * 	<= Role_head_group*
		 * 	<= Role_body_group*
		 * ```
		 */
		@ $mol_mem_key
		Role_form(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Role_head_group(id),
				this.Role_body_group(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * role_rows / <= Role_form*0
		 * ```
		 */
		role_rows() {
			return [
				this.Role_form("0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Roles_content $mol_list rows <= role_rows
		 * ```
		 */
		@ $mol_mem
		Roles_content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.role_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Roles_field $mol_form_field
		 * 	label /
		 * 		<= roles_field_name
		 * 		<= Role_add
		 * 	Content <= Roles_content
		 * ```
		 */
		@ $mol_mem
		Roles_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.label = () => [
				this.roles_field_name(),
				this.Role_add()
			] as readonly any[]
			obj.Content = () => this.Roles_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * member* $hyoo_idea_person
		 * ```
		 */
		@ $mol_mem_key
		member(id: any) {
			const obj = new this.$.$hyoo_idea_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * member_role* \
		 * ```
		 */
		member_role(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Member_role* $mol_paragraph title <= member_role*
		 * ```
		 */
		@ $mol_mem_key
		Member_role(id: any) {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.member_role(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Team_kick_icon* $mol_icon_cross
		 * ```
		 */
		@ $mol_mem_key
		Team_kick_icon(id: any) {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * team_kick*? null
		 * ```
		 */
		@ $mol_mem_key
		team_kick(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Team_kick* $mol_button_minor
		 * 	hint @ \Remove from team
		 * 	sub / <= Team_kick_icon*
		 * 	click? <=> team_kick*?
		 * ```
		 */
		@ $mol_mem_key
		Team_kick(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Team_kick_hint' )
			obj.sub = () => [
				this.Team_kick_icon(id)
			] as readonly any[]
			obj.click = (next?: any) => this.team_kick(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * team_actions* /
		 * 	<= Member_role*
		 * 	<= Team_kick*
		 * ```
		 */
		team_actions(id: any) {
			return [
				this.Member_role(id),
				this.Team_kick(id)
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Team_member*0_0 $hyoo_idea_person_card
		 * 	person <= member*
		 * 	actions <= team_actions*
		 * ```
		 */
		@ $mol_mem_key
		Team_member(id: any) {
			const obj = new this.$.$hyoo_idea_person_card()
			
			obj.person = () => this.member(id)
			obj.actions = () => this.team_actions(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * team_rows / <= Team_member*0_0
		 * ```
		 */
		team_rows() {
			return [
				this.Team_member("0_0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Team_content $mol_list rows <= team_rows
		 * ```
		 */
		@ $mol_mem
		Team_content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.team_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Team_field $mol_form_field
		 * 	name @ \Team
		 * 	Content <= Team_content
		 * ```
		 */
		@ $mol_mem
		Team_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Team_field_name' )
			obj.Content = () => this.Team_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Request_accept_icon* $mol_icon_check
		 * ```
		 */
		@ $mol_mem_key
		Request_accept_icon(id: any) {
			const obj = new this.$.$mol_icon_check()
			
			return obj
		}
		
		/**
		 * ```tree
		 * request_accept*? null
		 * ```
		 */
		@ $mol_mem_key
		request_accept(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Request_accept* $mol_button_minor
		 * 	hint @ \Accept invite
		 * 	sub / <= Request_accept_icon*
		 * 	click? <=> request_accept*?
		 * ```
		 */
		@ $mol_mem_key
		Request_accept(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Request_accept_hint' )
			obj.sub = () => [
				this.Request_accept_icon(id)
			] as readonly any[]
			obj.click = (next?: any) => this.request_accept(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Request_member*0_0 $hyoo_idea_person_card
		 * 	person <= member*
		 * 	actions / <= Request_accept*
		 * ```
		 */
		@ $mol_mem_key
		Request_member(id: any) {
			const obj = new this.$.$hyoo_idea_person_card()
			
			obj.person = () => this.member(id)
			obj.actions = () => [
				this.Request_accept(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * requests_rows / <= Request_member*0_0
		 * ```
		 */
		requests_rows() {
			return [
				this.Request_member("0_0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Requests_content $mol_list rows <= requests_rows
		 * ```
		 */
		@ $mol_mem
		Requests_content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.requests_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Requests_field $mol_form_field
		 * 	name @ \Requests
		 * 	Content <= Requests_content
		 * ```
		 */
		@ $mol_mem
		Requests_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Requests_field_name' )
			obj.Content = () => this.Requests_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Invite_cancel_icon* $mol_icon_cancel
		 * ```
		 */
		@ $mol_mem_key
		Invite_cancel_icon(id: any) {
			const obj = new this.$.$mol_icon_cancel()
			
			return obj
		}
		
		/**
		 * ```tree
		 * invite_cancel*? null
		 * ```
		 */
		@ $mol_mem_key
		invite_cancel(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Invite_cancel* $mol_button_minor
		 * 	hint @ \Cancel invite
		 * 	sub / <= Invite_cancel_icon*
		 * 	click? <=> invite_cancel*?
		 * ```
		 */
		@ $mol_mem_key
		Invite_cancel(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Invite_cancel_hint' )
			obj.sub = () => [
				this.Invite_cancel_icon(id)
			] as readonly any[]
			obj.click = (next?: any) => this.invite_cancel(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Invite_member*0_0 $hyoo_idea_person_card
		 * 	person <= member*
		 * 	actions / <= Invite_cancel*
		 * ```
		 */
		@ $mol_mem_key
		Invite_member(id: any) {
			const obj = new this.$.$hyoo_idea_person_card()
			
			obj.person = () => this.member(id)
			obj.actions = () => [
				this.Invite_cancel(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * invites_rows / <= Invite_member*0_0
		 * ```
		 */
		invites_rows() {
			return [
				this.Invite_member("0_0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Invites_content $mol_list rows <= invites_rows
		 * ```
		 */
		@ $mol_mem
		Invites_content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.invites_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Invites_field $mol_form_field
		 * 	name @ \Invites
		 * 	Content <= Invites_content
		 * ```
		 */
		@ $mol_mem
		Invites_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_idea_project_form_Invites_field_name' )
			obj.Content = () => this.Invites_content()
			
			return obj
		}
	}
	
}

