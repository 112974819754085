namespace $ {
	export class $mol_icon_comment_text_multiple extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M3,15H1V3C1,1.9 1.9,1 3,1H19V3H3V15M12,23C11.45,23 11,22.55 11,22V19H7C5.9,19 5,18.1 5,17V7C5,5.9 5.9,5 7,5H21C22.1,5 23,5.9 23,7V17C23,18.1 22.1,19 21,19H16.9L13.2,22.71C13,22.89 12.76,23 12.5,23H12M9,9V11H19V9H9M9,13V15H17V13H9Z
		 * ```
		 */
		path() {
			return "M3,15H1V3C1,1.9 1.9,1 3,1H19V3H3V15M12,23C11.45,23 11,22.55 11,22V19H7C5.9,19 5,18.1 5,17V7C5,5.9 5.9,5 7,5H21C22.1,5 23,5.9 23,7V17C23,18.1 22.1,19 21,19H16.9L13.2,22.71C13,22.89 12.76,23 12.5,23H12M9,9V11H19V9H9M9,13V15H17V13H9Z"
		}
	}
	
}

