namespace $.$$ {

	const { rem } = $mol_style_unit

	$mol_style_define( $.$hyoo_idea_project_form, {

		Role_form: $hyoo_idea_person_form_multy,
		Roles_field: {
			Label: {
				alignItems: 'center',
			},
		},

	} )

}
