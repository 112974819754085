namespace $ {
	export class $hyoo_idea_person_card extends $mol_view {
		
		/**
		 * ```tree
		 * id
		 * ```
		 */
		id() {
			return this.person().id()
		}
		
		/**
		 * ```tree
		 * name
		 * ```
		 */
		name() {
			return this.person().name()
		}
		
		/**
		 * ```tree
		 * avatar_node
		 * ```
		 */
		avatar_node() {
			return this.person().avatar_node()
		}
		
		/**
		 * ```tree
		 * person $hyoo_idea_person
		 * 	id => id
		 * 	name => name
		 * 	avatar_node => avatar_node
		 * ```
		 */
		@ $mol_mem
		person() {
			const obj = new this.$.$hyoo_idea_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * sub /
		 * 	<= Link
		 * 	<= Actions
		 * ```
		 */
		sub() {
			return [
				this.Link(),
				this.Actions()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Avatar $hyoo_idea_person_avatar blob <= avatar_node
		 * ```
		 */
		@ $mol_mem
		Avatar() {
			const obj = new this.$.$hyoo_idea_person_avatar()
			
			obj.blob = () => this.avatar_node()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name $mol_paragraph title <= name
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.name()
			
			return obj
		}
		
		/**
		 * ```tree
		 * name_sub /
		 * ```
		 */
		name_sub() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Name_sub $mol_paragraph sub <= name_sub
		 * ```
		 */
		@ $mol_mem
		Name_sub() {
			const obj = new this.$.$mol_paragraph()
			
			obj.sub = () => this.name_sub()
			
			return obj
		}
		
		/**
		 * ```tree
		 * info /
		 * 	<= Name
		 * 	<= Name_sub
		 * ```
		 */
		info() {
			return [
				this.Name(),
				this.Name_sub()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Info $mol_list rows <= info
		 * ```
		 */
		@ $mol_mem
		Info() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.info()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Link $mol_link
		 * 	arg * person <= id
		 * 	sub /
		 * 		<= Avatar
		 * 		<= Info
		 * ```
		 */
		@ $mol_mem
		Link() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				person: this.id()
			} as Record< string, any >)
			obj.sub = () => [
				this.Avatar(),
				this.Info()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * actions /
		 * ```
		 */
		actions() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Actions $mol_view sub <= actions
		 * ```
		 */
		@ $mol_mem
		Actions() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => this.actions()
			
			return obj
		}
	}
	
}

