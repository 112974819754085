namespace $.$$ {

	const { rem } = $mol_style_unit

	$mol_style_define( $.$hyoo_idea_projects, {

		flex: {
			basis: rem(20),
			shrink: 0,
		},
		
		Filter: {
			alignSelf: 'stretch',
		},
		
	} )

}
