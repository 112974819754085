namespace $ {
	export class $hyoo_idea_project_page extends $mol_page {
		
		/**
		 * ```tree
		 * auto / <= slides_send
		 * ```
		 */
		auto() {
			return [
				this.slides_send()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * domain
		 * ```
		 */
		domain() {
			return this.project().domain()
		}
		
		/**
		 * ```tree
		 * user_id
		 * ```
		 */
		user_id() {
			return this.project().domain().user().id()
		}
		
		/**
		 * ```tree
		 * id
		 * ```
		 */
		id() {
			return this.project().id()
		}
		
		/**
		 * ```tree
		 * logo_node
		 * ```
		 */
		logo_node() {
			return this.project().logo_node()
		}
		
		/**
		 * ```tree
		 * logo
		 * ```
		 */
		logo() {
			return this.project().logo()
		}
		
		/**
		 * ```tree
		 * name
		 * ```
		 */
		name() {
			return this.project().name()
		}
		
		/**
		 * ```tree
		 * brief
		 * ```
		 */
		brief() {
			return this.project().brief()
		}
		
		/**
		 * ```tree
		 * site
		 * ```
		 */
		site() {
			return this.project().site()
		}
		
		/**
		 * ```tree
		 * stage
		 * ```
		 */
		stage() {
			return this.project().stage()
		}
		
		/**
		 * ```tree
		 * description
		 * ```
		 */
		description() {
			return this.project().description()
		}
		
		/**
		 * ```tree
		 * project $hyoo_idea_project
		 * 	domain => domain
		 * 	domain().user().id => user_id
		 * 	id => id
		 * 	logo_node => logo_node
		 * 	logo => logo
		 * 	name => name
		 * 	brief => brief
		 * 	site => site
		 * 	stage => stage
		 * 	description => description
		 * ```
		 */
		@ $mol_mem
		project() {
			const obj = new this.$.$hyoo_idea_project()
			
			return obj
		}
		
		/**
		 * ```tree
		 * title @ \Project
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_idea_project_page_title' )
		}
		
		/**
		 * ```tree
		 * slides_content \
		 * 	\# {name}
		 * 	\
		 * 	\> {brief}
		 * 	\
		 * 	\{description}
		 * ```
		 */
		slides_content() {
			return "# {name}\n\n> {brief}\n\n{description}"
		}
		
		/**
		 * ```tree
		 * self false
		 * ```
		 */
		self() {
			return false
		}
		
		/**
		 * ```tree
		 * team_member_no_role @ \Member
		 * ```
		 */
		team_member_no_role() {
			return this.$.$mol_locale.text( '$hyoo_idea_project_page_team_member_no_role' )
		}
		
		/**
		 * ```tree
		 * tools /
		 * 	<= Slides
		 * 	<= Edit
		 * 	<= Close
		 * ```
		 */
		tools() {
			return [
				this.Slides(),
				this.Edit(),
				this.Close()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * editing false
		 * ```
		 */
		editing() {
			return false
		}
		
		/**
		 * ```tree
		 * Form $hyoo_idea_project_form project <= project
		 * ```
		 */
		@ $mol_mem
		Form() {
			const obj = new this.$.$hyoo_idea_project_form()
			
			obj.project = () => this.project()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Title $mol_view sub /
		 * 	<= Logo
		 * 	<= Face_list
		 * ```
		 */
		@ $mol_mem
		Title() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Logo(),
				this.Face_list()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * body /
		 * 	<= Main
		 * 	<= Team
		 * 	<= Posts
		 * 	<= Post_list
		 * ```
		 */
		body() {
			return [
				this.Main(),
				this.Team(),
				this.Posts(),
				this.Post_list()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * slides_send null
		 * ```
		 */
		slides_send() {
			return null as any
		}
		
		/**
		 * ```tree
		 * slides \https://slides.hyoo.ru/#!slides={source}
		 * ```
		 */
		slides() {
			return "https://slides.hyoo.ru/#!slides={source}"
		}
		
		/**
		 * ```tree
		 * Slides $mol_link_iconed
		 * 	uri <= slides
		 * 	title \
		 * ```
		 */
		@ $mol_mem
		Slides() {
			const obj = new this.$.$mol_link_iconed()
			
			obj.uri = () => this.slides()
			obj.title = () => ""
			
			return obj
		}
		
		/**
		 * ```tree
		 * Edit_icon $mol_icon_pencil
		 * ```
		 */
		@ $mol_mem
		Edit_icon() {
			const obj = new this.$.$mol_icon_pencil()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Edit $mol_link
		 * 	arg * project_edit \
		 * 	sub / <= Edit_icon
		 * ```
		 */
		@ $mol_mem
		Edit() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				project_edit: ""
			} as Record< string, any >)
			obj.sub = () => [
				this.Edit_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Close_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Close_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Close $mol_link
		 * 	arg *
		 * 		project null
		 * 		project_edit null
		 * 	sub / <= Close_icon
		 * ```
		 */
		@ $mol_mem
		Close() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				project: null as any,
				project_edit: null as any
			} as Record< string, any >)
			obj.sub = () => [
				this.Close_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Logo $hyoo_idea_project_logo blob <= logo_node
		 * ```
		 */
		@ $mol_mem
		Logo() {
			const obj = new this.$.$hyoo_idea_project_logo()
			
			obj.blob = () => this.logo_node()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name $mol_paragraph title <= name
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.name()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Brief $mol_paragraph title <= brief
		 * ```
		 */
		@ $mol_mem
		Brief() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.brief()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Face_list $mol_list rows /
		 * 	<= Name
		 * 	<= Brief
		 * ```
		 */
		@ $mol_mem
		Face_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Name(),
				this.Brief()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Stage $hyoo_idea_project_stage
		 * 	enabled false
		 * 	stage <= stage
		 * ```
		 */
		@ $mol_mem
		Stage() {
			const obj = new this.$.$hyoo_idea_project_stage()
			
			obj.enabled = () => false
			obj.stage = () => this.stage()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Site $mol_link_iconed
		 * 	uri <= site
		 * 	hint @ \Project Site
		 * ```
		 */
		@ $mol_mem
		Site() {
			const obj = new this.$.$mol_link_iconed()
			
			obj.uri = () => this.site()
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_project_page_Site_hint' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * Description $mol_text text <= description
		 * ```
		 */
		@ $mol_mem
		Description() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.description()
			
			return obj
		}
		
		/**
		 * ```tree
		 * main /
		 * 	<= Stage
		 * 	<= Site
		 * 	<= Description
		 * ```
		 */
		main() {
			return [
				this.Stage(),
				this.Site(),
				this.Description()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Main $mol_list rows <= main
		 * ```
		 */
		@ $mol_mem
		Main() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.main()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Request_button $mol_link
		 * 	arg *
		 * 		invite_project <= id
		 * 		invite_person <= user_id
		 * 	title @ \Want to join team
		 * ```
		 */
		@ $mol_mem
		Request_button() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				invite_project: this.id(),
				invite_person: this.user_id()
			} as Record< string, any >)
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_project_page_Request_button_title' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * team_member* $hyoo_idea_person
		 * ```
		 */
		@ $mol_mem_key
		team_member(id: any) {
			const obj = new this.$.$hyoo_idea_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * team_member_role* \
		 * ```
		 */
		team_member_role(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Team_member*0_0 $hyoo_idea_person_card
		 * 	person <= team_member*
		 * 	name_sub / <= team_member_role*
		 * ```
		 */
		@ $mol_mem_key
		Team_member(id: any) {
			const obj = new this.$.$hyoo_idea_person_card()
			
			obj.person = () => this.team_member(id)
			obj.name_sub = () => [
				this.team_member_role(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * team_member_list / <= Team_member*0_0
		 * ```
		 */
		team_member_list() {
			return [
				this.Team_member("0_0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Team_list $mol_list rows <= team_member_list
		 * ```
		 */
		@ $mol_mem
		Team_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.team_member_list()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Team $mol_section
		 * 	title @ \Team
		 * 	tools / <= Request_button
		 * 	content / <= Team_list
		 * ```
		 */
		@ $mol_mem
		Team() {
			const obj = new this.$.$mol_section()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_project_page_Team_title' )
			obj.tools = () => [
				this.Request_button()
			] as readonly any[]
			obj.content = () => [
				this.Team_list()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Posts_title @ \Posts: {count}
		 * ```
		 */
		Posts_title() {
			return this.$.$mol_locale.text( '$hyoo_idea_project_page_Posts_title' )
		}
		
		/**
		 * ```tree
		 * post_add? null
		 * ```
		 */
		@ $mol_mem
		post_add(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Post_add $hyoo_idea_post_add submit? <=> post_add?
		 * ```
		 */
		@ $mol_mem
		Post_add() {
			const obj = new this.$.$hyoo_idea_post_add()
			
			obj.submit = (next?: any) => this.post_add(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * posts_content / <= Post_add
		 * ```
		 */
		posts_content() {
			return [
				this.Post_add()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Posts $mol_section
		 * 	title <= Posts_title
		 * 	content <= posts_content
		 * ```
		 */
		@ $mol_mem
		Posts() {
			const obj = new this.$.$mol_section()
			
			obj.title = () => this.Posts_title()
			obj.content = () => this.posts_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * post* $hyoo_idea_post
		 * ```
		 */
		@ $mol_mem_key
		post(id: any) {
			const obj = new this.$.$hyoo_idea_post()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Post*0_0 $hyoo_idea_post_card post <= post*
		 * ```
		 */
		@ $mol_mem_key
		Post(id: any) {
			const obj = new this.$.$hyoo_idea_post_card()
			
			obj.post = () => this.post(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * post_list / <= Post*0_0
		 * ```
		 */
		post_list() {
			return [
				this.Post("0_0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Post_list $mol_list rows <= post_list
		 * ```
		 */
		@ $mol_mem
		Post_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.post_list()
			
			return obj
		}
	}
	
}

