namespace $ {
	export class $hyoo_idea_post_card extends $mol_list {
		
		/**
		 * ```tree
		 * id
		 * ```
		 */
		id() {
			return this.post().id()
		}
		
		/**
		 * ```tree
		 * content
		 * ```
		 */
		content() {
			return this.post().content()
		}
		
		/**
		 * ```tree
		 * created_moment
		 * ```
		 */
		created_moment() {
			return this.post().created_moment()
		}
		
		/**
		 * ```tree
		 * project
		 * ```
		 */
		project() {
			return this.post().project()
		}
		
		/**
		 * ```tree
		 * likes_total
		 * ```
		 */
		likes_total() {
			return this.post().likes_total()
		}
		
		/**
		 * ```tree
		 * liked?
		 * ```
		 */
		liked(next?: any) {
			return this.post().liked(next)
		}
		
		/**
		 * ```tree
		 * post $hyoo_idea_post
		 * 	id => id
		 * 	content => content
		 * 	created_moment => created_moment
		 * 	project => project
		 * 	likes_total => likes_total
		 * 	liked? => liked?
		 * ```
		 */
		@ $mol_mem
		post() {
			const obj = new this.$.$hyoo_idea_post()
			
			return obj
		}
		
		/**
		 * ```tree
		 * rows /
		 * 	<= Head
		 * 	<= Content
		 * 	<= Foot
		 * 	<= Comments
		 * ```
		 */
		rows() {
			return [
				this.Head(),
				this.Content(),
				this.Foot(),
				this.Comments()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Head $hyoo_idea_project_card project <= project
		 * ```
		 */
		@ $mol_mem
		Head() {
			const obj = new this.$.$hyoo_idea_project_card()
			
			obj.project = () => this.project()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Content $mol_text text <= content
		 * ```
		 */
		@ $mol_mem
		Content() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Comments_icon $mol_icon_comment_text_multiple
		 * ```
		 */
		@ $mol_mem
		Comments_icon() {
			const obj = new this.$.$mol_icon_comment_text_multiple()
			
			return obj
		}
		
		/**
		 * ```tree
		 * comments_count 0
		 * ```
		 */
		comments_count() {
			return 0
		}
		
		/**
		 * ```tree
		 * comments_showed? false
		 * ```
		 */
		@ $mol_mem
		comments_showed(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Comments_toggle $mol_check_icon
		 * 	Icon <= Comments_icon
		 * 	label / <= comments_count
		 * 	checked? <=> comments_showed?
		 * ```
		 */
		@ $mol_mem
		Comments_toggle() {
			const obj = new this.$.$mol_check_icon()
			
			obj.Icon = () => this.Comments_icon()
			obj.label = () => [
				this.comments_count()
			] as readonly any[]
			obj.checked = (next?: any) => this.comments_showed(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Foot $mol_view sub / <= Comments_toggle
		 * ```
		 */
		@ $mol_mem
		Foot() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Comments_toggle()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * comment_author* $hyoo_idea_person
		 * ```
		 */
		@ $mol_mem_key
		comment_author(id: any) {
			const obj = new this.$.$hyoo_idea_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * comment_created_moment* $mol_time_moment
		 * ```
		 */
		@ $mol_mem_key
		comment_created_moment(id: any) {
			const obj = new this.$.$mol_time_moment()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Comment_ago* $hyoo_idea_ago
		 * 	title @ \{count} {unit} назад
		 * 	moment <= comment_created_moment*
		 * ```
		 */
		@ $mol_mem_key
		Comment_ago(id: any) {
			const obj = new this.$.$hyoo_idea_ago()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_post_card_Comment_ago_title' )
			obj.moment = () => this.comment_created_moment(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Comment_author* $hyoo_idea_person_card
		 * 	person <= comment_author*
		 * 	actions / <= Comment_ago*
		 * ```
		 */
		@ $mol_mem_key
		Comment_author(id: any) {
			const obj = new this.$.$hyoo_idea_person_card()
			
			obj.person = () => this.comment_author(id)
			obj.actions = () => [
				this.Comment_ago(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * comment_text* \
		 * ```
		 */
		comment_text(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Comment_text* $mol_text text <= comment_text*
		 * ```
		 */
		@ $mol_mem_key
		Comment_text(id: any) {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.comment_text(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_sub_list* $mol_list rows / <= Comment_text*
		 * ```
		 */
		@ $mol_mem_key
		Name_sub_list(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Comment_text(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Comment*0_0 $mol_list rows /
		 * 	<= Comment_author*
		 * 	<= Name_sub_list*
		 * ```
		 */
		@ $mol_mem_key
		Comment(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Comment_author(id),
				this.Name_sub_list(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * comment? \
		 * ```
		 */
		@ $mol_mem
		comment(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Text $mol_textarea
		 * 	hint @ \New comment..
		 * 	value? <=> comment?
		 * ```
		 */
		@ $mol_mem
		Text() {
			const obj = new this.$.$mol_textarea()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_post_card_Text_hint' )
			obj.value = (next?: any) => this.comment(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * comment_add? null
		 * ```
		 */
		@ $mol_mem
		comment_add(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Submit_icon $mol_icon_send
		 * ```
		 */
		@ $mol_mem
		Submit_icon() {
			const obj = new this.$.$mol_icon_send()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Submit $mol_button_minor
		 * 	hint @ \Send comment
		 * 	click? <=> comment_add?
		 * 	sub / <= Submit_icon
		 * ```
		 */
		@ $mol_mem
		Submit() {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_post_card_Submit_hint' )
			obj.click = (next?: any) => this.comment_add(next)
			obj.sub = () => [
				this.Submit_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Comment_add $mol_bar sub /
		 * 	<= Text
		 * 	<= Submit
		 * ```
		 */
		@ $mol_mem
		Comment_add() {
			const obj = new this.$.$mol_bar()
			
			obj.sub = () => [
				this.Text(),
				this.Submit()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * comment_rows /
		 * 	<= Comment*0_0
		 * 	<= Comment_add
		 * ```
		 */
		comment_rows() {
			return [
				this.Comment("0_0"),
				this.Comment_add()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Comments $mol_list rows <= comment_rows
		 * ```
		 */
		@ $mol_mem
		Comments() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.comment_rows()
			
			return obj
		}
	}
	
}

