namespace $.$$ {

	const { per } = $mol_style_unit

	$mol_style_define( $hyoo_idea_post_add, {
		
		alignItems: 'flex-end',

		Text: {
			flex: {
				shrink: 1,
			},
		},

	} )

}
