namespace $ {
	export class $hyoo_idea_ago extends $mol_view {
		
		/**
		 * ```tree
		 * param_count \{count}
		 * ```
		 */
		param_count() {
			return "{count}"
		}
		
		/**
		 * ```tree
		 * param_unit \{unit}
		 * ```
		 */
		param_unit() {
			return "{unit}"
		}
		
		/**
		 * ```tree
		 * title @ \published {count} {unit} ago
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_idea_ago_title' )
		}
		
		/**
		 * ```tree
		 * now @ \now
		 * ```
		 */
		now() {
			return this.$.$mol_locale.text( '$hyoo_idea_ago_now' )
		}
		
		/**
		 * ```tree
		 * moment $mol_time_moment
		 * ```
		 */
		@ $mol_mem
		moment() {
			const obj = new this.$.$mol_time_moment()
			
			return obj
		}
		
		/**
		 * ```tree
		 * sub / <= ago
		 * ```
		 */
		sub() {
			return [
				this.ago()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * units *
		 * 	second <= second
		 * 	minute <= minute
		 * 	hour <= hour
		 * 	day <= day
		 * 	month <= month
		 * 	year <= year
		 * ```
		 */
		units() {
			return {
				second: this.second(),
				minute: this.minute(),
				hour: this.hour(),
				day: this.day(),
				month: this.month(),
				year: this.year()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * ago \
		 * ```
		 */
		ago() {
			return ""
		}
		
		/**
		 * ```tree
		 * second $hyoo_idea_plural
		 * 	other @ \seconds
		 * 	one @ \second
		 * 	two @ \
		 * 	few @ \
		 * 	many @ \
		 * ```
		 */
		@ $mol_mem
		second() {
			const obj = new this.$.$hyoo_idea_plural()
			
			obj.other = () => this.$.$mol_locale.text( '$hyoo_idea_ago_second_other' )
			obj.one = () => this.$.$mol_locale.text( '$hyoo_idea_ago_second_one' )
			obj.two = () => this.$.$mol_locale.text( '$hyoo_idea_ago_second_two' )
			obj.few = () => this.$.$mol_locale.text( '$hyoo_idea_ago_second_few' )
			obj.many = () => this.$.$mol_locale.text( '$hyoo_idea_ago_second_many' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * minute $hyoo_idea_plural
		 * 	other @ \minutes
		 * 	one @ \minute
		 * 	two @ \
		 * 	few @ \
		 * 	many @ \
		 * ```
		 */
		@ $mol_mem
		minute() {
			const obj = new this.$.$hyoo_idea_plural()
			
			obj.other = () => this.$.$mol_locale.text( '$hyoo_idea_ago_minute_other' )
			obj.one = () => this.$.$mol_locale.text( '$hyoo_idea_ago_minute_one' )
			obj.two = () => this.$.$mol_locale.text( '$hyoo_idea_ago_minute_two' )
			obj.few = () => this.$.$mol_locale.text( '$hyoo_idea_ago_minute_few' )
			obj.many = () => this.$.$mol_locale.text( '$hyoo_idea_ago_minute_many' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * hour $hyoo_idea_plural
		 * 	other @ \hours
		 * 	one @ \hour
		 * 	two @ \
		 * 	few @ \
		 * 	many @ \
		 * ```
		 */
		@ $mol_mem
		hour() {
			const obj = new this.$.$hyoo_idea_plural()
			
			obj.other = () => this.$.$mol_locale.text( '$hyoo_idea_ago_hour_other' )
			obj.one = () => this.$.$mol_locale.text( '$hyoo_idea_ago_hour_one' )
			obj.two = () => this.$.$mol_locale.text( '$hyoo_idea_ago_hour_two' )
			obj.few = () => this.$.$mol_locale.text( '$hyoo_idea_ago_hour_few' )
			obj.many = () => this.$.$mol_locale.text( '$hyoo_idea_ago_hour_many' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * day $hyoo_idea_plural
		 * 	other @ \days
		 * 	one @ \day
		 * 	two @ \
		 * 	few @ \
		 * 	many @ \
		 * ```
		 */
		@ $mol_mem
		day() {
			const obj = new this.$.$hyoo_idea_plural()
			
			obj.other = () => this.$.$mol_locale.text( '$hyoo_idea_ago_day_other' )
			obj.one = () => this.$.$mol_locale.text( '$hyoo_idea_ago_day_one' )
			obj.two = () => this.$.$mol_locale.text( '$hyoo_idea_ago_day_two' )
			obj.few = () => this.$.$mol_locale.text( '$hyoo_idea_ago_day_few' )
			obj.many = () => this.$.$mol_locale.text( '$hyoo_idea_ago_day_many' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * month $hyoo_idea_plural
		 * 	other @ \months
		 * 	one @ \month
		 * 	two @ \
		 * 	few @ \
		 * 	many @ \
		 * ```
		 */
		@ $mol_mem
		month() {
			const obj = new this.$.$hyoo_idea_plural()
			
			obj.other = () => this.$.$mol_locale.text( '$hyoo_idea_ago_month_other' )
			obj.one = () => this.$.$mol_locale.text( '$hyoo_idea_ago_month_one' )
			obj.two = () => this.$.$mol_locale.text( '$hyoo_idea_ago_month_two' )
			obj.few = () => this.$.$mol_locale.text( '$hyoo_idea_ago_month_few' )
			obj.many = () => this.$.$mol_locale.text( '$hyoo_idea_ago_month_many' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * year $hyoo_idea_plural
		 * 	other @ \years
		 * 	one @ \year
		 * 	two @ \
		 * 	few @ \
		 * 	many @ \
		 * ```
		 */
		@ $mol_mem
		year() {
			const obj = new this.$.$hyoo_idea_plural()
			
			obj.other = () => this.$.$mol_locale.text( '$hyoo_idea_ago_year_other' )
			obj.one = () => this.$.$mol_locale.text( '$hyoo_idea_ago_year_one' )
			obj.two = () => this.$.$mol_locale.text( '$hyoo_idea_ago_year_two' )
			obj.few = () => this.$.$mol_locale.text( '$hyoo_idea_ago_year_few' )
			obj.many = () => this.$.$mol_locale.text( '$hyoo_idea_ago_year_many' )
			
			return obj
		}
	}
	
}

