namespace $ {
	export class $hyoo_idea_person_avatar extends $mol_image {
		
		/**
		 * ```tree
		 * blob null
		 * ```
		 */
		blob() {
			return null as any
		}
		
		/**
		 * ```tree
		 * placeholder \https://robohash.org/${id}.png
		 * ```
		 */
		placeholder() {
			return "https://robohash.org/${id}.png"
		}
	}
	
}

