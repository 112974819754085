namespace $ {
	export class $hyoo_idea_post_add extends $mol_bar {
		
		/**
		 * ```tree
		 * submit? null
		 * ```
		 */
		@ $mol_mem
		submit(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * sub /
		 * 	<= Text
		 * 	<= Submit
		 * ```
		 */
		sub() {
			return [
				this.Text(),
				this.Submit()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * text? \
		 * ```
		 */
		@ $mol_mem
		text(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Text $mol_textarea
		 * 	hint @ \New post..
		 * 	value? <=> text?
		 * ```
		 */
		@ $mol_mem
		Text() {
			const obj = new this.$.$mol_textarea()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_post_add_Text_hint' )
			obj.value = (next?: any) => this.text(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * event_submit? null
		 * ```
		 */
		@ $mol_mem
		event_submit(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Submit_icon $mol_icon_arrow_down_bold
		 * ```
		 */
		@ $mol_mem
		Submit_icon() {
			const obj = new this.$.$mol_icon_arrow_down_bold()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Submit $mol_button_minor
		 * 	hint @ \Post
		 * 	click? <=> event_submit?
		 * 	sub / <= Submit_icon
		 * ```
		 */
		@ $mol_mem
		Submit() {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_post_add_Submit_hint' )
			obj.click = (next?: any) => this.event_submit(next)
			obj.sub = () => [
				this.Submit_icon()
			] as readonly any[]
			
			return obj
		}
	}
	
}

