namespace $ {
	export class $hyoo_idea_project_card extends $mol_link {
		
		/**
		 * ```tree
		 * arg * project <= id
		 * ```
		 */
		arg() {
			return {
				project: this.id()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * id
		 * ```
		 */
		id() {
			return this.project().id()
		}
		
		/**
		 * ```tree
		 * logo_node
		 * ```
		 */
		logo_node() {
			return this.project().logo_node()
		}
		
		/**
		 * ```tree
		 * brief
		 * ```
		 */
		brief() {
			return this.project().brief()
		}
		
		/**
		 * ```tree
		 * project $hyoo_idea_project
		 * 	id => id
		 * 	logo_node => logo_node
		 * 	brief => brief
		 * ```
		 */
		@ $mol_mem
		project() {
			const obj = new this.$.$hyoo_idea_project()
			
			return obj
		}
		
		/**
		 * ```tree
		 * default_name @ \Untitled Project
		 * ```
		 */
		default_name() {
			return this.$.$mol_locale.text( '$hyoo_idea_project_card_default_name' )
		}
		
		/**
		 * ```tree
		 * sub /
		 * 	<= Logo
		 * 	<= List
		 * ```
		 */
		sub() {
			return [
				this.Logo(),
				this.List()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Logo $hyoo_idea_project_logo blob <= logo_node
		 * ```
		 */
		@ $mol_mem
		Logo() {
			const obj = new this.$.$hyoo_idea_project_logo()
			
			obj.blob = () => this.logo_node()
			
			return obj
		}
		
		/**
		 * ```tree
		 * project_name \
		 * ```
		 */
		project_name() {
			return ""
		}
		
		/**
		 * ```tree
		 * highlight \
		 * ```
		 */
		highlight() {
			return ""
		}
		
		/**
		 * ```tree
		 * Name $mol_dimmer
		 * 	haystack <= project_name
		 * 	needle <= highlight
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_dimmer()
			
			obj.haystack = () => this.project_name()
			obj.needle = () => this.highlight()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Brief $mol_dimmer
		 * 	haystack <= brief
		 * 	needle <= highlight
		 * ```
		 */
		@ $mol_mem
		Brief() {
			const obj = new this.$.$mol_dimmer()
			
			obj.haystack = () => this.brief()
			obj.needle = () => this.highlight()
			
			return obj
		}
		
		/**
		 * ```tree
		 * List $mol_list rows /
		 * 	<= Name
		 * 	<= Brief
		 * ```
		 */
		@ $mol_mem
		List() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Name(),
				this.Brief()
			] as readonly any[]
			
			return obj
		}
	}
	
}

