namespace $ {
	export class $hyoo_idea_person_page extends $mol_page {
		
		/**
		 * ```tree
		 * id
		 * ```
		 */
		id() {
			return this.person().id()
		}
		
		/**
		 * ```tree
		 * domain
		 * ```
		 */
		domain() {
			return this.person().domain()
		}
		
		/**
		 * ```tree
		 * avatar_node
		 * ```
		 */
		avatar_node() {
			return this.person().avatar_node()
		}
		
		/**
		 * ```tree
		 * name
		 * ```
		 */
		name() {
			return this.person().name()
		}
		
		/**
		 * ```tree
		 * status
		 * ```
		 */
		status() {
			return this.person().status()
		}
		
		/**
		 * ```tree
		 * about
		 * ```
		 */
		about() {
			return this.person().about()
		}
		
		/**
		 * ```tree
		 * phone
		 * ```
		 */
		phone() {
			return this.person().phone()
		}
		
		/**
		 * ```tree
		 * email
		 * ```
		 */
		email() {
			return this.person().email()
		}
		
		/**
		 * ```tree
		 * projects
		 * ```
		 */
		projects() {
			return this.person().projects()
		}
		
		/**
		 * ```tree
		 * person $hyoo_idea_person
		 * 	id => id
		 * 	domain => domain
		 * 	avatar_node => avatar_node
		 * 	name => name
		 * 	status => status
		 * 	about => about
		 * 	phone => phone
		 * 	email => email
		 * 	projects => projects
		 * ```
		 */
		@ $mol_mem
		person() {
			const obj = new this.$.$hyoo_idea_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * self false
		 * ```
		 */
		self() {
			return false
		}
		
		/**
		 * ```tree
		 * title @ \User page
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_idea_person_page_title' )
		}
		
		/**
		 * ```tree
		 * msg * job_present @ \Up to present
		 * ```
		 */
		msg() {
			return {
				job_present: this.$.$mol_locale.text( '$hyoo_idea_person_page_msg_job_present' )
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * tools /
		 * 	<= Main_tools
		 * 	<= Actions
		 * ```
		 */
		tools() {
			return [
				this.Main_tools(),
				this.Actions()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Edit_form $hyoo_idea_person_form person <= person
		 * ```
		 */
		@ $mol_mem
		Edit_form() {
			const obj = new this.$.$hyoo_idea_person_form()
			
			obj.person = () => this.person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Projects
		 * ```
		 */
		Projects() {
			return this.Project_list().Rows()
		}
		
		/**
		 * ```tree
		 * Project_list $hyoo_idea_projects
		 * 	projects <= projects
		 * 	Rows => Projects
		 * ```
		 */
		@ $mol_mem
		Project_list() {
			const obj = new this.$.$hyoo_idea_projects()
			
			obj.projects = () => this.projects()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Title $mol_view sub /
		 * 	<= Avatar
		 * 	<= Face_list
		 * ```
		 */
		@ $mol_mem
		Title() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Avatar(),
				this.Face_list()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * body /
		 * 	<= Neck
		 * 	<= About
		 * 	<= Jobs
		 * 	<= Education
		 * 	<= Projects_block
		 * ```
		 */
		body() {
			return [
				this.Neck(),
				this.About(),
				this.Jobs(),
				this.Education(),
				this.Projects_block()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Edit_icon $mol_icon_pencil
		 * ```
		 */
		@ $mol_mem
		Edit_icon() {
			const obj = new this.$.$mol_icon_pencil()
			
			return obj
		}
		
		/**
		 * ```tree
		 * editing? false
		 * ```
		 */
		@ $mol_mem
		editing(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Edit $mol_check_icon
		 * 	hint @ \Edit profile
		 * 	Icon <= Edit_icon
		 * 	checked? <=> editing?
		 * ```
		 */
		@ $mol_mem
		Edit() {
			const obj = new this.$.$mol_check_icon()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_person_page_Edit_hint' )
			obj.Icon = () => this.Edit_icon()
			obj.checked = (next?: any) => this.editing(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Close_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Close_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Close $mol_link
		 * 	arg *
		 * 		person null
		 * 		person_edit null
		 * 	sub / <= Close_icon
		 * ```
		 */
		@ $mol_mem
		Close() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				person: null as any,
				person_edit: null as any
			} as Record< string, any >)
			obj.sub = () => [
				this.Close_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Main_tools $mol_view sub /
		 * 	<= Edit
		 * 	<= Close
		 * ```
		 */
		@ $mol_mem
		Main_tools() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Edit(),
				this.Close()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Invite_button $mol_link
		 * 	arg * invite_person <= id
		 * 	title @ \Invite to project
		 * ```
		 */
		@ $mol_mem
		Invite_button() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				invite_person: this.id()
			} as Record< string, any >)
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_person_page_Invite_button_title' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * actions / <= Invite_button
		 * ```
		 */
		actions() {
			return [
				this.Invite_button()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Actions $mol_view sub <= actions
		 * ```
		 */
		@ $mol_mem
		Actions() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => this.actions()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Avatar $hyoo_idea_person_avatar blob <= avatar_node
		 * ```
		 */
		@ $mol_mem
		Avatar() {
			const obj = new this.$.$hyoo_idea_person_avatar()
			
			obj.blob = () => this.avatar_node()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name $mol_paragraph title <= name
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.name()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Status $mol_paragraph title <= status
		 * ```
		 */
		@ $mol_mem
		Status() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.status()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Face_list $mol_list rows /
		 * 	<= Name
		 * 	<= Status
		 * ```
		 */
		@ $mol_mem
		Face_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Name(),
				this.Status()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * position @ \{position} at {company}
		 * ```
		 */
		position() {
			return this.$.$mol_locale.text( '$hyoo_idea_person_page_position' )
		}
		
		/**
		 * ```tree
		 * Position $mol_paragraph title <= position
		 * ```
		 */
		@ $mol_mem
		Position() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.position()
			
			return obj
		}
		
		/**
		 * ```tree
		 * location @ \{city}
		 * ```
		 */
		location() {
			return this.$.$mol_locale.text( '$hyoo_idea_person_page_location' )
		}
		
		/**
		 * ```tree
		 * Location $mol_paragraph title <= location
		 * ```
		 */
		@ $mol_mem
		Location() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.location()
			
			return obj
		}
		
		/**
		 * ```tree
		 * summary_rows /
		 * 	<= Position
		 * 	<= Location
		 * ```
		 */
		summary_rows() {
			return [
				this.Position(),
				this.Location()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Summary $mol_list rows <= summary_rows
		 * ```
		 */
		@ $mol_mem
		Summary() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.summary_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * phone_uri \
		 * ```
		 */
		phone_uri() {
			return ""
		}
		
		/**
		 * ```tree
		 * Phone $mol_link
		 * 	uri <= phone_uri
		 * 	title <= phone
		 * ```
		 */
		@ $mol_mem
		Phone() {
			const obj = new this.$.$mol_link()
			
			obj.uri = () => this.phone_uri()
			obj.title = () => this.phone()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Contacts_phone $mol_view sub / <= Phone
		 * ```
		 */
		@ $mol_mem
		Contacts_phone() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Phone()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * email_uri \
		 * ```
		 */
		email_uri() {
			return ""
		}
		
		/**
		 * ```tree
		 * Email $mol_link
		 * 	uri <= email_uri
		 * 	title <= email
		 * ```
		 */
		@ $mol_mem
		Email() {
			const obj = new this.$.$mol_link()
			
			obj.uri = () => this.email_uri()
			obj.title = () => this.email()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Contacts_email $mol_view sub / <= Email
		 * ```
		 */
		@ $mol_mem
		Contacts_email() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Email()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * contacts_content /
		 * 	<= Contacts_phone
		 * 	<= Contacts_email
		 * ```
		 */
		contacts_content() {
			return [
				this.Contacts_phone(),
				this.Contacts_email()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Contacts $mol_list rows <= contacts_content
		 * ```
		 */
		@ $mol_mem
		Contacts() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.contacts_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * neck /
		 * 	<= Summary
		 * 	<= Contacts
		 * ```
		 */
		neck() {
			return [
				this.Summary(),
				this.Contacts()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Neck $mol_row sub <= neck
		 * ```
		 */
		@ $mol_mem
		Neck() {
			const obj = new this.$.$mol_row()
			
			obj.sub = () => this.neck()
			
			return obj
		}
		
		/**
		 * ```tree
		 * About $mol_text text <= about
		 * ```
		 */
		@ $mol_mem
		About() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.about()
			
			return obj
		}
		
		/**
		 * ```tree
		 * job_position* \
		 * ```
		 */
		job_position(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Job_position* $mol_paragraph title <= job_position*
		 * ```
		 */
		@ $mol_mem_key
		Job_position(id: any) {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.job_position(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * job_company* \
		 * ```
		 */
		job_company(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Job_company* $mol_paragraph title <= job_company*
		 * ```
		 */
		@ $mol_mem_key
		Job_company(id: any) {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.job_company(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * job_dates* \
		 * ```
		 */
		job_dates(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Job_dates* $mol_paragraph title <= job_dates*
		 * ```
		 */
		@ $mol_mem_key
		Job_dates(id: any) {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.job_dates(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * job_functions* \
		 * ```
		 */
		job_functions(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Job_functions* $mol_text text <= job_functions*
		 * ```
		 */
		@ $mol_mem_key
		Job_functions(id: any) {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.job_functions(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Job*0 $mol_list rows /
		 * 	<= Job_position*
		 * 	<= Job_company*
		 * 	<= Job_dates*
		 * 	<= Job_functions*
		 * ```
		 */
		@ $mol_mem_key
		Job(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Job_position(id),
				this.Job_company(id),
				this.Job_dates(id),
				this.Job_functions(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * jobs / <= Job*0
		 * ```
		 */
		jobs() {
			return [
				this.Job("0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Jobs_list $mol_list rows <= jobs
		 * ```
		 */
		@ $mol_mem
		Jobs_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.jobs()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Jobs $mol_section
		 * 	title @ \Experience
		 * 	content / <= Jobs_list
		 * ```
		 */
		@ $mol_mem
		Jobs() {
			const obj = new this.$.$mol_section()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_person_page_Jobs_title' )
			obj.content = () => [
				this.Jobs_list()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * education_head* \
		 * ```
		 */
		education_head(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Education_head* $mol_paragraph title <= education_head*
		 * ```
		 */
		@ $mol_mem_key
		Education_head(id: any) {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.education_head(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * education_details* \
		 * ```
		 */
		education_details(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Education_details* $mol_paragraph title <= education_details*
		 * ```
		 */
		@ $mol_mem_key
		Education_details(id: any) {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.education_details(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Education_row*0 $mol_list rows /
		 * 	<= Education_head*
		 * 	<= Education_details*
		 * ```
		 */
		@ $mol_mem_key
		Education_row(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Education_head(id),
				this.Education_details(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * education / <= Education_row*0
		 * ```
		 */
		education() {
			return [
				this.Education_row("0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Education_list $mol_list rows <= education
		 * ```
		 */
		@ $mol_mem
		Education_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.education()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Education $mol_section
		 * 	title @ \Education
		 * 	content / <= Education_list
		 * ```
		 */
		@ $mol_mem
		Education() {
			const obj = new this.$.$mol_section()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_idea_person_page_Education_title' )
			obj.content = () => [
				this.Education_list()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Projects_block_title @ \Projects: {count}
		 * ```
		 */
		Projects_block_title() {
			return this.$.$mol_locale.text( '$hyoo_idea_person_page_Projects_block_title' )
		}
		
		/**
		 * ```tree
		 * Projects_block $mol_section
		 * 	title <= Projects_block_title
		 * 	content / <= Projects
		 * ```
		 */
		@ $mol_mem
		Projects_block() {
			const obj = new this.$.$mol_section()
			
			obj.title = () => this.Projects_block_title()
			obj.content = () => [
				this.Projects()
			] as readonly any[]
			
			return obj
		}
	}
	
}

