namespace $ {
	export class $hyoo_idea_project_stage extends $mol_switch {
		
		/**
		 * ```tree
		 * options <= stages
		 * ```
		 */
		options() {
			return this.stages()
		}
		
		/**
		 * ```tree
		 * value? <=> stage?
		 * ```
		 */
		value(next?: any) {
			return this.stage(next)
		}
		
		/**
		 * ```tree
		 * stages *
		 * 	idea @ \💡 Idea..
		 * 	proto @ \🎨 Prototype..
		 * 	pilot @ \🏆 Pilot..
		 * 	product @ \💰 Product!
		 * ```
		 */
		stages() {
			return {
				idea: this.$.$mol_locale.text( '$hyoo_idea_project_stage_stages_idea' ),
				proto: this.$.$mol_locale.text( '$hyoo_idea_project_stage_stages_proto' ),
				pilot: this.$.$mol_locale.text( '$hyoo_idea_project_stage_stages_pilot' ),
				product: this.$.$mol_locale.text( '$hyoo_idea_project_stage_stages_product' )
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * stage? \idea
		 * ```
		 */
		@ $mol_mem
		stage(next?: any) {
			if ( next !== undefined ) return next as never
			return "idea"
		}
	}
	
}

