namespace $ {
	export class $mol_icon_send extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M2,21L23,12L2,3V10L17,12L2,14V21Z
		 * ```
		 */
		path() {
			return "M2,21L23,12L2,3V10L17,12L2,14V21Z"
		}
	}
	
}

