namespace $ {
	export class $mol_icon_delete extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19C6,20.1 6.9,21 8,21H16C17.1,21 18,20.1 18,19V7H6V19Z
		 * ```
		 */
		path() {
			return "M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19C6,20.1 6.9,21 8,21H16C17.1,21 18,20.1 18,19V7H6V19Z"
		}
	}
	
}

