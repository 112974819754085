namespace $ {
	export class $hyoo_idea_projects extends $mol_page {
		
		/**
		 * ```tree
		 * domain $hyoo_idea_domain
		 * ```
		 */
		@ $mol_mem
		domain() {
			const obj = new this.$.$hyoo_idea_domain()
			
			return obj
		}
		
		/**
		 * ```tree
		 * projects /$hyoo_idea_project
		 * ```
		 */
		projects() {
			return [
			] as readonly $hyoo_idea_project[]
		}
		
		/**
		 * ```tree
		 * self false
		 * ```
		 */
		self() {
			return false
		}
		
		/**
		 * ```tree
		 * title @ \Projects
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_idea_projects_title' )
		}
		
		/**
		 * ```tree
		 * tools /
		 * 	<= Add
		 * 	<= Close
		 * ```
		 */
		tools() {
			return [
				this.Add(),
				this.Close()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body / <= Rows
		 * ```
		 */
		body() {
			return [
				this.Rows()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Add_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		Add_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * add? null
		 * ```
		 */
		@ $mol_mem
		add(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Add $mol_button_minor
		 * 	hint @ \Create project
		 * 	sub / <= Add_icon
		 * 	click? <=> add?
		 * ```
		 */
		@ $mol_mem
		Add() {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_idea_projects_Add_hint' )
			obj.sub = () => [
				this.Add_icon()
			] as readonly any[]
			obj.click = (next?: any) => this.add(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Close null
		 * ```
		 */
		Close() {
			return null as any
		}
		
		/**
		 * ```tree
		 * empty_title @ \Create first project ⬆
		 * ```
		 */
		empty_title() {
			return this.$.$mol_locale.text( '$hyoo_idea_projects_empty_title' )
		}
		
		/**
		 * ```tree
		 * Empty $mol_view sub / <= empty_title
		 * ```
		 */
		@ $mol_mem
		Empty() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.empty_title()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * filter? \
		 * ```
		 */
		@ $mol_mem
		filter(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Filter $mol_search query? <=> filter?
		 * ```
		 */
		@ $mol_mem
		Filter() {
			const obj = new this.$.$mol_search()
			
			obj.query = (next?: any) => this.filter(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * project* $hyoo_idea_project
		 * ```
		 */
		@ $mol_mem_key
		project(id: any) {
			const obj = new this.$.$hyoo_idea_project()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Card*0_0 $hyoo_idea_project_card
		 * 	project <= project*
		 * 	highlight <= filter
		 * ```
		 */
		@ $mol_mem_key
		Card(id: any) {
			const obj = new this.$.$hyoo_idea_project_card()
			
			obj.project = () => this.project(id)
			obj.highlight = () => this.filter()
			
			return obj
		}
		
		/**
		 * ```tree
		 * project_rows /
		 * 	<= Filter
		 * 	<= Card*0_0
		 * ```
		 */
		project_rows() {
			return [
				this.Filter(),
				this.Card("0_0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Rows $mol_list
		 * 	Empty <= Empty
		 * 	rows <= project_rows
		 * ```
		 */
		@ $mol_mem
		Rows() {
			const obj = new this.$.$mol_list()
			
			obj.Empty = () => this.Empty()
			obj.rows = () => this.project_rows()
			
			return obj
		}
	}
	
}

